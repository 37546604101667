import {useState, useRef, useEffect} from 'react';

import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";

import Modal from "components/general/Modal";
import ModalFooter from "@material-tailwind/react/ModalFooter";

import VerificationModal from 'components/verify/VerificationModal';
import Pagination from 'components/general/Pagination';

import {getKycDocs} from 'routes/kycRoutes';

import {useGlobalState} from 'state-pool';
import {format} from 'date-fns';
import {isValidJson} from 'utils';

import {vbApi} from 'api/vb';

import {useGlobal} from 'hooks/useGlobalState';
import { useAuth } from 'hooks/useAuth';


import md5 from 'blueimp-md5'

const KYC_NAMES = {
    0: 'Unverified',
    1: 'Level 1',
    2: 'Level 2',
    3: 'Level 3',
};

const PER_PAGE = 10;

export default function UsersTable({selected}) {
    const [users] = useGlobalState('users');
    const [usersDisplay, setUsersDisplay] = useState(users);

    const [showVerificationModal, setShowVerificationModal] = useState(false);
    const [verifyLevel, setVerifyLevel] = useState(null);
    const [docsToVerify, setDocsToVerify] = useState(null);
    const [userToVerify, setUserToVerify] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [numPages, setNumPages] = useState(1);
    const [showFrom, setShowFrom] = useState(0);
    const [showTo, setShowTo] = useState(PER_PAGE);
    const [showDisableConfirm, setShowDisableConfirm] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [deleteUser, setDeleteUser] = useState(null);
    const [disableUser, setDisableUser] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [misc, setMisc] = useState({});
    const [showEnableConfirm, setShowEnableConfirm] = useState(false);
    const [enableUser, setEnableUser] = useState(null);
    
    const {refresh} = useGlobal();
    const {currentUser, getAllUserDetails, userPassword} = useAuth();
    
    const deleteRef = useRef();
    const disableRef = useRef();
    const enableRef = useRef();
    const loginAs = useRef();

    useEffect(() => {
        if(users && Array.isArray(users) && users.length > 0) {
            const numUsers = usersDisplay && usersDisplay?.length > 0 ? usersDisplay?.length : users.length;

            const tempNumPages = Math.ceil(numUsers / PER_PAGE);

            setNumPages(tempNumPages);
        }
    }, [users, usersDisplay]);

    useEffect(() => {
        setShowFrom(currentPage * PER_PAGE);
        setShowTo((currentPage * PER_PAGE) + PER_PAGE);
    }, [currentPage]);
    
    async function onVerifyClick(user) {
        const {kyc_pending, misc, id} = user;
        
        const parsedMisc = (isValidJson(misc) ? JSON.parse(misc) : false);

        setMisc(parsedMisc);
        
        getKycDocs(id).then(res => res && res.data && setDocsToVerify(res.data))

        setUserToVerify(user);
        setVerifyLevel(kyc_pending);
        if(parsedMisc) {
            setDocsToVerify(parsedMisc);
        }

        setShowVerificationModal(true);
    }

    function onDeleteClick(user) {

        setDeleteUser(user);
        setShowDeleteConfirm(true);
    }

    function onDisableClick(user) {

        setDisableUser(user);
        setShowDisableConfirm(true);
    }

    function onEnableClick(user) {

        setEnableUser(user);
        setShowEnableConfirm(true);
    }

    function _onEnableClick() {

        if(enableUser && enableUser.id) {

            vbApi.get(`/admin/user/disableuser/${enableUser.id}/${1}`).then(_ => {
                setShowEnableConfirm(false);
                    
                refresh(`/admin/user`, 'users');
            });
        }
    }
    
    
    function _onDeleteClick() {
        if(deleteUser && deleteUser.id) {
            vbApi.delete(`/admin/user/deleteuser/${deleteUser.id}`).then(_ => {
                setShowDeleteConfirm(false);

                refresh(`/admin/user`, 'users');
            });
        }

    }

    function _onDisableClick() {

        if(disableUser && disableUser.id) {

            vbApi.get(`/admin/user/disableuser/${disableUser.id}/${0}`).then(_ => {
                setShowDisableConfirm(false);
                    
                refresh(`/admin/user`, 'users');
            });
        }
    }

    useEffect(() => {
        if(!selected || selected === 'all') {
            setUsersDisplay(users);

            return;
        }

        if(selected === 'pending') {
            const filteredUsers = users && users.filter(user => user && user.kyc_pending);

            setUsersDisplay([...filteredUsers]);
        }

        if(selected === 'disabled') {
            const filteredUsers = users && users.filter(user => user && (user.is_enabled !== 1));

            setUsersDisplay([...filteredUsers]);
        }

        if(selected === 'enabled') {
            const filteredUsers = users && users.filter(user => user && (user.is_enabled === 1));

            setUsersDisplay([...filteredUsers]);
        }

    }, [selected, users]);


    getAllUserDetails(currentUser?.id)


    const loginAsUser = (id) => {
        let date = new Date();
        date.setDate(date.getDate() + 1)
        let currentDate = date.getFullYear().toString() + date.getDate().toString() + date.getMonth().toString() + date.getHours().toString()
        try {
            const md5Key = md5(userPassword + currentDate)
            currentUser?.id ? window.open(`https://app.venturebay.online/loginas/${currentUser?.id}/${id}/${md5Key}`) : window.open(`https://app.venturebay.online/loginas/${id}/${md5Key}`)
        } catch(e) {
            console.log(e)
        }
    }

    return (
        <>
            <Card className="bg-primary">
                <CardHeader color="gray" contentPosition="left">
                    <h2 className="text-white text-2xl">Users</h2>
                </CardHeader>
                <CardBody>
                    <div className="overflow-x-auto">
                        <table className="items-center w-full bg-transparent border-collapse">
                            <thead>
                                <tr>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Name
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Email
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Current verification status
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Pending verification status
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Created
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {usersDisplay && Array.isArray(usersDisplay) && usersDisplay.length > 0 && usersDisplay.slice(showFrom, showTo).map((user, i) => (
                                    <tr key={i}>
                                        <th className="border-b border-gray-200 align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-white text-left">
                                            {user.name_first}{" "}{user.name_last}
                                        </th>
                                        <th className="border-b border-gray-200 align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-white text-left">
                                            {user.email}
                                        </th>
                                        <th className="border-b border-gray-200 align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-white text-left">
                                            {KYC_NAMES[user.kyc_current]}
                                        </th>
                                        <th className="border-b border-gray-200 align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-white text-left">
                                            {/* {KYC_NAMES[user.kyc_pending]} */}
                                            <div className="flex items-center">
                                                {
                                                user.kyc_pending
                                                ? (
                                                    <>
                                                        <span className="font-bold">
                                                            Level {user.kyc_pending}
                                                        </span>
                                                        
                                                        <Button
                                                            size="sm"
                                                            className="ml-2"
                                                            onClick={() => onVerifyClick(user)}
                                                        >
                                                            Verify
                                                        </Button>
                                                    </>
                                                ) : "None"}

                                            </div>
                                        </th>
                                        <th className="border-b border-gray-200 align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-white text-left">
                                            {user.created_at && format(new Date(user.created_at), 'yyyy-MM-dd')}
                                        </th>
                                        <th className="border-b border-gray-200 align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-white text-left">
                                            {user.is_activated === 1 ? <span className="text-success">Activated</span> : <span className="text-danger">Not activated</span>}
                                        </th>
                                        <th className="border-b border-gray-200 align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-white text-left">
                                            {user.is_enabled === 1 ? <span className="text-success">Enabled</span> : <span className="text-danger">Disabled</span>}
                                        </th>
                                        <th className="border-b border-gray-200 align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-white text-left">
                                            <div className="flex">
                                                {/* <Link 
                                                    to={`/users/view/${user.id}`}
                                                >
                                                    <Button
                                                        size="sm"
                                                        color="gray"
                                                        iconOnly
                                                        rounded
                                                        ref={viewRef}
                                                    >
                                                        <Icon name="visibility" color="white" />
                                                    </Button>
                                                    <Tooltips placement="bottom" ref={viewRef}>
                                                        <TooltipsContent>View user</TooltipsContent>
                                                    </Tooltips>
                                                </Link> */}
                                                {
                                                    user.is_enabled
                                                    ?<>
                                                        <Button
                                                            size="sm"
                                                            color="orange"
                                                            className="ml-2"
                                                            onClick={() => onDisableClick(user)}
                                                            iconOnly
                                                            rounded
                                                            ref={disableRef}
                                                        >
                                                            <Icon name="block" color="white" />
                                                        </Button>
                                                        <Tooltips placement="bottom" ref={disableRef}>
                                                            <TooltipsContent>Disable user</TooltipsContent>
                                                        </Tooltips>
                                                    </>
                                                    :<>
                                                        <Button
                                                            size="sm"
                                                            color="lightGreen"
                                                            className="ml-2"
                                                            onClick={() => onEnableClick(user)}
                                                            iconOnly
                                                            rounded
                                                            ref={enableRef}
                                                        >
                                                            <Icon name="check" color="white" />
                                                        </Button>
                                                        <Tooltips placement="bottom" ref={enableRef}>
                                                            <TooltipsContent>Enable user</TooltipsContent>
                                                        </Tooltips>
                                                    </>
                                                }
                                                <Button
                                                    size="sm"
                                                    color="red"
                                                    className="ml-2"
                                                    onClick={() => onDeleteClick(user)}
                                                    iconOnly
                                                    rounded
                                                    ref={deleteRef}
                                                >
                                                    <Icon name="delete" color="white" />
                                                </Button>
                                                <Tooltips placement="bottom" ref={deleteRef}>
                                                    <TooltipsContent>Delete user</TooltipsContent>
                                                </Tooltips>
                                                <Button
                                                    size="sm"
                                                    color="blue"
                                                    className="ml-2"
                                                    onClick={() => loginAsUser(user.id)}
                                                    iconOnly
                                                    rounded
                                                    ref={loginAs}
                                                >
                                                    <Icon name="cast" color="white" />
                                                </Button>
                                                <Tooltips placement="bottom" ref={loginAs}>
                                                    <TooltipsContent>Login as this user</TooltipsContent>
                                                </Tooltips>
                                            </div>
                                        </th>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
            <div className='flex w-100 justify-center'>
                <Pagination 
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    numPages={numPages}
                />
            </div>
            <VerificationModal
                showVerificationModal={showVerificationModal}
                setShowVerificationModal={setShowVerificationModal}
                level={verifyLevel}
                docs={docsToVerify}
                user={userToVerify}
                misc={misc}
            />
            <Modal 
                showModal={showDisableConfirm}
                setShowModal={setShowDisableConfirm}
                size="lg"   
                title="Are you sure you want to disable this user?"
            >
                <ModalFooter>
                    <Button 
                        color="red"
                        buttonType="link"
                        onClick={() => setShowDisableConfirm(false)}
                        ripple="dark"
                    >
                        Close
                    </Button>

                    <Button
                        color="green"
                        onClick={() => _onDisableClick()}
                        ripple="light"
                    >
                        <span className='text-black'>Disable</span>
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal 
                showModal={showEnableConfirm}
                setShowModal={setShowEnableConfirm}
                size="lg"   
                title="Are you sure you want to enable this user?"
            >
                <ModalFooter>
                    <Button 
                        color="red"
                        buttonType="link"
                        onClick={() => setShowEnableConfirm(false)}
                        ripple="dark"
                    >
                        Close
                    </Button>

                    <Button
                        color="green"
                        onClick={() => _onEnableClick()}
                        ripple="light"
                    >
                        <span className='text-black'>Enable</span>
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal 
                showModal={showDeleteConfirm}
                setShowModal={setShowDeleteConfirm}
                size="lg"   
                title="Are you sure you want to delete this user?"
            >
                <ModalFooter>
                    <Button 
                        color="red"
                        buttonType="link"
                        onClick={() => setShowDeleteConfirm(false)}
                        ripple="dark"
                    >
                        Close
                    </Button>

                    <Button
                        color="red"
                        onClick={() => _onDeleteClick()}
                        ripple="light"
                    >
                        Delete
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
