import Icon from '@material-tailwind/react/Icon';

import Card from 'components/cards/Card';

export default function UserCategories({selected, onOptionClick}) {
    return (
        <div className="flex flex-col lg:flex-row p-4 gap-2">
            <div className="w-1-3 md:w-full" onClick={() => onOptionClick('all')}>
                <Card className={selected === "all" ? "bg-primary shadow-2xl cursor-pointer border-green border-2" : "bg-green-500 shadow-2xl cursor-pointer border-primary border-2"}>
                    <div className="flex items-center h-full">
                        <Icon name="star" size="3xl" color={selected === "all" ? "yellow" : "black"} />
                        <h3 className={`ml-4 ${selected === "all" ? "text-green" : "text-black"}`}>All</h3>
                    </div>
                </Card>
            </div>
            <div className="w-1-3 md:w-full" onClick={() => onOptionClick('pending')}>
                <Card className={selected === "pending" ? "bg-primary shadow-2xl cursor-pointer border-green border-2" : "bg-green-500 shadow-2xl cursor-pointer border-primary border-2"}>
                    <div className="flex items-center h-full">
                        <Icon name="hourglass_empty" size="3xl" color={selected === "pending" ? "yellow" : "black"}/>
                        <h3 className={`ml-4 ${selected === "pending" ? "text-green" : "text-black"}`}>Pending</h3>
                    </div>
                </Card>
            </div>
            <div className="w-1-3 md:w-full" onClick={() => onOptionClick('disabled')}>
                <Card className={selected === "disabled" ? "bg-primary shadow-2xl cursor-pointer border-green border-2" : "bg-green-500 shadow-2xl cursor-pointer border-primary border-2"}>
                    <div className="flex items-center h-full">
                        <Icon name="close" size="3xl" color={selected === "disabled" ? "yellow" : "black"} />
                        <h3 className={`ml-4 ${selected === "disabled" ? "text-green" : "text-black"}`}>Disabled</h3>
                    </div>
                </Card>
            </div>
            <div className="w-1-3 md:w-full" onClick={() => onOptionClick('enabled')}>
                <Card className={selected === "enabled" ? "bg-primary shadow-2xl cursor-pointer border-green border-2" : "bg-green-500 shadow-2xl cursor-pointer border-primary border-2"}>
                    <div className="flex items-center h-full">
                        <Icon name="check_circle" size="3xl" color={selected === "enabled" ? "yellow" : "black"} />
                        <h3 className={`ml-4 ${selected === "enabled" ? "text-green" : "text-black"}`}>Enabled</h3>
                    </div>
                </Card>
            </div>
        </div>
    )
}
