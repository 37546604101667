import VerifyLevelOne from 'components/verify/VerifyLevelOne';
import VerifyLevelTwo from 'components/verify/VerifyLevelTwo';
import VerifyLevelThree from 'components/verify/VerifyLevelThree';
import BackButton from 'components/buttons/BackButton';

import { Helmet } from 'react-helmet';

export default function Verify() {
    const level = 2;

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Verify - Venturbay</title>
        </Helmet>
        <div className="px-3 md:px-8 xl:px-20 relative">
            <div className="h-5" />
            <BackButton />
            <div className="h-5" />
            <h3 className="text-white text-3xl">{`Verify level ${level} for Calvin Adams`}</h3>
            {level === 1 && <VerifyLevelOne />}
            {level === 2 && <VerifyLevelTwo />}
            {level === 3 && <VerifyLevelThree />}
            <div className="h-10" />
        </div></>
    )
}
