import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import { Helmet } from 'react-helmet';

import UsersTable from 'components/users/UsersTable';
import UserCategories from 'components/users/UserCategories';

export default function Users() {
    const {selectedparam} = useParams();
    const [selected, setSelected] = useState();

    useEffect(() => {
        if(!selectedparam) setSelected('all');

        if(selectedparam) setSelected(selectedparam)
    }, [selectedparam]);

    function onOptionClick(option) {
        setSelected(option);
    }

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Users - Venturbay</title>
        </Helmet>
        <div className="px-3 md:px-8 h-auto mt-24">
            <UserCategories selected={selected} setSelected={setSelected} onOptionClick={onOptionClick} />
            <div className="h-16" />
            <div className="container mx-auto max-w-full">
                <div className="grid grid-cols-1 px-4 mb-16">
                    <UsersTable selected={selected} />
                </div>
            </div>
        </div></>
    );
}
