import Alert from "@material-tailwind/react/ClosingAlert";

import {useAlert} from 'hooks/useAlert';

const AlertMesage = () => {
    const {
        showAlert,
        alertMessage,
        alertTitle,
        alertType
    } = useAlert();

    // const alertIcons = {
    //     info: Info,
    //     success: Success,
    //     warning: Warning,
    //     danger: ErrorIcon
    // }

    const colors = {
        info: "lightBlue",
        success: "lightGreen",
        warning: "orange",
        danger: "red"
    }

    return (
        <>
            {showAlert && (
                <Alert style={style} color={colors[alertType]}>
                    <span>
                        <b>{alertTitle}</b> {alertMessage}
                    </span>
                </Alert>
            )}
        </>
    )
}

const style = {
    position: "fixed", 
    bottom: "-1em", 
    width: "100%", 
    zIndex: 9999
}

export default AlertMesage;