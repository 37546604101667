import {useState} from 'react';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

import {useHistory} from 'react-router-dom';

import { useGlobalState } from 'state-pool';
import { useAuth } from 'hooks/useAuth';

export default function UpdateEmail() {
     const [email, setEmail] = useState('');
     const [error, setError] = useState({});
     const [currentUser] = useGlobalState('currentUser');
 
     const history = useHistory();
 
     const {commit, logout} = useAuth();
 
     function clearError(key) {
         if (key && typeof key === 'string') {
             const tempErrors = error;
 
             tempErrors[key] = null;
 
             setError(tempErrors);
         }
     }
 
     async function onDoneClick() {
         let tempErrors = {};
         if (!email) {
             tempErrors.email = 'Please enter your new email address';
         }
         // if (!password) {
         //     tempErrors.password = 'Please enter your password';
         // }
         if (tempErrors && typeof tempErrors === 'object' && Object.keys(tempErrors).length > 0) {
             setError(tempErrors);
 
             return;
         }
 
         const isSuccessful = await commit({...currentUser, email}, '/user/edituser');
 
         if(isSuccessful) logout();
     }

    return (
        <div className="h-screen px-3 md:px-8 flex relative justify-center">
            <div className=" absolute left-1 top-5 md:top-3">
                <Button
                    color="white"
                    rounded
                    className="hover:pl-3 text-white"
                    buttonType="link"
                    size="lg"
                    ripple="dark"
                    onClick={() => history.goBack()}
                >
                    <Icon name="arrow_back" size="sm" />
                    Back           
                </Button>
            </div>
            <div className="mt-20">
                <h3 className="font-normal text-4xl text-white">Update email address</h3>
                <div className="h-2" />
                <Paragraph color="white">
                Enter your new email address below
                </Paragraph>
                <Card className="max-w-3xl text-left bg-primary">  
                    <div className="h-2" />
                    <CardBody>
                        <div className="mb-4 text-left">
                            <Input
                                outline={false}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                type="text"
                                color="green"
                                placeholder="Your new email"
                                error={error && error.email}
                                size="regular"
                                onFocus={() => clearError('email')}
                            />
                        </div>

                        {/* <div className="mb-4 text-left">
                            <Input
                                outline={false}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                type="password"
                                color="green"
                                placeholder="Password"
                                size="regular"
                            />
                        </div> */}

                    </CardBody>
                    <CardFooter>
                        <Button
                            color="green"
                            
                            className="hover:bg-green hover:text-white w-full hover:shadow-xl hover:pr-2"
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                            onClick={onDoneClick}
                        >
                            Done
                            <Icon name="arrow_forward" size="sm" />
                        </Button>
                        <div className="h-5" />
                        <Paragraph color="white">
                            When clicking done, you'll be logged out of the system.  
                        </Paragraph>
                        <Paragraph color="white">
                            We'll send an email to the new email provided, click on the link emailed to you to log back in to the system. 
                        </Paragraph>
                    </CardFooter>
                </Card>
                <div className="h-10" />
            </div>
        </div>
    );
}
