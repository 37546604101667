import {useState} from 'react';
import {useParams} from 'react-router-dom';

import TransactionCategories from 'components/transactions/TransactionCategories';
import TransactionsTable from 'components/transactions/TransactionsTable';

import { Helmet } from 'react-helmet';

export default function Transactions() {
    const {selectedparam} = useParams();
    const [selected, setSelected] = useState(selectedparam ? selectedparam : 'all');

    function onOptionClick(option) {
        setSelected(option);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Transactions - Venturbay</title>
            </Helmet>
            <div className="px-3 md:px-8 h-auto mt-10">
                <TransactionCategories selected={selected} setSelected={setSelected} onOptionClick={onOptionClick} />
                <div className="h-16" />
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-0 mb-16">
                        <TransactionsTable selected={selected} />
                    </div>
                </div>
            </div>
        </>
    );
}
