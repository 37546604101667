import {useState} from 'react';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

import {useHistory} from 'react-router-dom';

import { passwordReset } from 'routes/userRoutes';

import { useAuth } from 'hooks/useAuth';

export default function ChangePassword() {
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState({});
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const {logout} = useAuth();
    const history = useHistory();

    function clearError(key) {
        if (key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError(tempErrors);
        }
    }

    const userId = JSON.parse(localStorage.getItem('currentUser')).id

    async function onDoneClick() {
        let tempErrors = {};
        if (!newPassword) {
            tempErrors.newPassword = 'Please enter your new password';
        }
        if (!confirmNewPassword) {
            tempErrors.confirmNewPassword = 'Please confirm your new password';
        }
        if (newPassword !== confirmNewPassword) {
            
            if (confirmNewPassword) {
                tempErrors.confirmNewPassword = 'passwords must match';
            }

        }
        if (tempErrors && typeof tempErrors === 'object' && Object.keys(tempErrors).length > 0) {
            setError(tempErrors);

            return;
        }

        const body = {
            confirm_password: confirmNewPassword,
            password: newPassword
        };

        const isSuccessful = await passwordReset(userId, body);

        if(isSuccessful) logout();
    }

    return (
        <div className="h-screen px-3 md:px-8 flex relative justify-center">
            <div className=" absolute left-1 top-5 md:top-3">
                <Button
                    color="white"
                    rounded
                    className="hover:pl-3 text-white"
                    buttonType="link"
                    size="lg"
                    ripple="dark"
                    onClick={() => history.goBack()}
                >
                    <Icon name="arrow_back" size="sm" />
                    Back           
                </Button>
            </div>
            <div className="mt-20">
                <h3 className="font-normal text-4xl text-white">Change password</h3>
                <div className="h-2" />
                <Paragraph color="white">
                Please enter your new password
                </Paragraph>
                <Card className="max-w-3xl text-left bg-primary">  
                    <div className="h-2" />
                    <CardBody>
                        <div className="mb-4 text-left">
                            <Input
                                outline={false}
                                value={newPassword}
                                onChange={e => setNewPassword(e.target.value)}
                                type="password"
                                color="green"
                                error={error && error.newPassword}
                                onFocus={() => clearError('newPassword')}
                                placeholder="New password"
                                size="regular"
                            />
                        </div>

                        <div className="mb-4 text-left">
                            <Input
                                outline={false}
                                value={confirmNewPassword}
                                onChange={e => setConfirmNewPassword(e.target.value)}
                                type="password"
                                color="green"
                                error={error && error.confirmNewPassword}
                                onFocus={() => clearError('confirmNewPassword')}
                                placeholder="Confirm new password"
                                size="regular"
                            />
                        </div>

                    </CardBody>
                    <CardFooter>
                        <Button
                            color="green"
                            
                            className="hover:bg-green hover:text-white w-full hover:shadow-xl hover:pr-2"
                            buttonType="outline"
                            size="lg"
                            ripple="dark"
                            onClick={onDoneClick}
                        >
                            Done
                            <Icon name="arrow_forward" size="sm" />
                        </Button>
                        <div className="h-5" />
                        <Paragraph color="white">
                        Password must be at least 8 characters long. The more characters, the better.
                        A mixture of both uppercase and lowercase letters.
                        A mixture of letters and numbers.
                        Inclusion of at least one special character, e.g., ! @ # ?
                        </Paragraph>
                    </CardFooter>
                </Card>
                <div className="h-10" />
            </div>
        </div>
    );
}
