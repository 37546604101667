import axios from "axios";

import {checkTokens} from 'utils/auth';

const vbApi = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});

// request middleware
vbApi.interceptors.request.use(async function (config: any) {
    
    const tokens = await checkTokens();

    if(tokens && typeof tokens === "object" && tokens.accessToken) {
        config.headers.Authorization = `Bearer ${tokens.accessToken}`;
    }

    return config;
}, function (error: any) {
    return Promise.reject(error);
});

export {
    vbApi
};