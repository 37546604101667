import ProfileDetails from 'components/profile/ProfileDetails';
import ProfileActions from 'components/profile/ProfileActions';
import { Helmet } from 'react-helmet';

export default function Dashboard() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Profile - Venturbay</title>
            </Helmet>
            <div className="bg-transparent px-3 md:px-8 h-24" />

            <div className="px-3 md:px-8 -mt-20">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 xl:grid-cols-6">
                        <div className="xl:col-start-1 xl:col-end-4 px-4 mb-5">
                            <ProfileDetails />
                        </div>
                        <div className="xl:col-start-4 xl:col-end-7 px-4 mb-5">
                            <ProfileActions />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
