export function toFixed(x) {
    if (Math.abs(x) < 1.0) {
        let e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        let e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}

export function bitorsat(val) {
    if (val/100000000 > 0.0001) {
       let numIn = val/100000000
       numIn +="";                                            // To cater to numric entries
     var sign="";                                           // To remember the number sign
     // numIn.charAt(0)=="-" && (numIn =numIn.substring(1),sign ="-"); // remove - sign & remember it
     var str = numIn.split(/[eE]/g);                        // Split numberic string at e or E
     if (str.length<2) return `BTC ${sign+numIn}`;                   // Not an Exponent Number? Exit with orginal Num back
     var power = str[1];                                    // Get Exponent (Power) (could be + or -)
     if (power ===0 || power === -0) return `BTC ${sign+str[0]}`;       // If 0 exponents (i.e. 0|-0|+0) then That's any easy one
    
     var deciSp = 1.1.toLocaleString().substring(1,2);  // Get Deciaml Separator
     str = str[0].split(deciSp);                        // Split the Base Number into LH and RH at the decimal point
     var baseRH = str[1] || "",                         // RH Base part. Make sure we have a RH fraction else ""
         baseLH = str[0];                               // LH base part.
    
      if (power>0) {   // ------- Positive Exponents (Process the RH Base Part)
         if (power> baseRH.length) baseRH +="0".repeat(power-baseRH.length); // Pad with "0" at RH
         baseRH = baseRH.slice(0,power) + deciSp + baseRH.slice(power);      // Insert decSep at the correct place into RH base
         if (baseRH.charAt(baseRH.length-1) ===deciSp) baseRH =baseRH.slice(0,-1); // If decSep at RH end? => remove it
    
      } else {         // ------- Negative Exponents (Process the LH Base Part)
         let num= Math.abs(power) - baseLH.length;                               // Delta necessary 0's
         if (num>0) baseLH = "0".repeat(num) + baseLH;                       // Pad with "0" at LH
         baseLH = baseLH.slice(0, power) + deciSp + baseLH.slice(power);     // Insert "." at the correct place into LH base
         if (baseLH.charAt(0) === deciSp) baseLH="0" + baseLH;                // If decSep at LH most? => add "0"
    }
    return `${sign + baseLH + baseRH} BTC`; 
    }
    return `${val} SAT`;
 }