import { Button, Icon } from '@material-tailwind/react';
import { useHistory } from 'react-router';

export default function BackButton(props) {
    const history = useHistory();

    return (
        <Button
            color="white"
            rounded
            className="pl-0 hover:-ml-2 text-white"
            buttonType="link"
            size="lg"
            ripple="dark"
            onClick={props.onClick ? props.onClick : () => history.goBack()}
            {...props}
        >
            <Icon name="arrow_back" color="white" size="sm" />
            Back           
        </Button>
    )
}


