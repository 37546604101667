export default function FundView({fund}) {
    return (
        <div className="w-full text-left">
            {fund && (
                <>
                    <div className="gs-12">
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Name</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{fund.name}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Movement</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{fund.movement}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Min amount</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{fund.min_amount}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Users limit</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{fund.users_limit}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Users current</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{fund.users_current}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Spread quantity</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{fund.spread_quantity}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Spread items</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{fund.spread_items}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Last rebalance</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{fund.rebalance_last}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Next rebalance</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{fund.rebalance_next}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Rebalance frequency</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{fund.rebalance_frequency}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Rebalance days</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{fund.rebalance_days}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Admin fees total</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{fund.admin_fees_total}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Admin fee in multiplier</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{fund.admin_fee_in_multi}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Admin fee out multiplier</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{fund.admin_fee_out_multi}</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}