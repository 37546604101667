export default function UserDetails({user}) {

    return (
        <div className="w-full text-left">
            {user && (
                <>
                    <div className="gs-12">
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Legal first name</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{user.name_first}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Legal surname</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{user.name_last}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">ID number</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{user.identification_number}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Address</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{user.physical_address}</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}