import { useEffect } from 'react';

// routing
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

//PWA components
import UpdateAvailable from 'components/pwa/updatePwa/UpdateAvailable';

// pages
import Dashboard from 'pages/Dashboard' // ?
import NotFound from 'pages/404';
import Login from 'pages/Login';
import Profile from 'pages/Profile';
import Verify from 'pages/Verify';

import Transactions from 'pages/Transactions';

import Users from 'pages/Users';
import AddUser from 'pages/UserAdd';

import Funds from 'pages/Funds';
import AddFund from 'pages/FundAdd';
import EditFund from 'pages/FundEdit';
// manage fund page?

// ui components
import Alert from 'components/general/Alert';
// import Footer from 'components/Footer';
import Sidebar from 'components/layout/Sidebar';

// profile components (forms)
import UpdateEmail from 'components/profile/UpdateEmail';
import ChangePassword from 'components/profile/ChangePassword';

// manage global state hook
import { useGlobal } from 'hooks/useGlobalState';
// import {useGlobalState} from 'state-pool';

// utility functions
import { requestNotificationPermission, isIos } from 'utils';

// scrolls to top when page changes
import ScrollToTop from 'components/general/ScrollToTop';

// authentication hook
import { useAuth } from 'hooks/useAuth';

// auth guard
import PrivateRoute from 'components/routing/PrivateRoute';

// import {userViewFund, linkedUsers} from 'routes/userRoutes';

// global styling
import "theme/utilities.css";
import "theme/core.css";
import "theme/fonts.css";
// Font Awesome Style Sheet
import '@fortawesome/fontawesome-free/css/all.min.css';

// Tailwind CSS Style Sheet
import 'assets/styles/tailwind.css';

function App() {
    const { load, autoRefresh } = useGlobal();
    const {currentUser} = useAuth();
    // const [currentUser] = useGlobalState('currentUser');

    useEffect(() => {
        // userViewFund(1).then(res => console.log('VIEW FUND', res));
        // linkedUsers(4).then(res => console.log('LINKED USERS', res));

        const ios = isIos();

        // if not ios then request permission for push notifications
        if (!ios) requestNotificationPermission();
    }, []);

    useEffect(() => {
        if(currentUser && currentUser.id) {
            // load users funds - identifies user through
            load('/admin/funds', 'funds');
            load('/admin/user', 'users');
            load(`/admin/user/viewusertransactions`,'transactions');
            // set up auto refresh 
            // const hours = 0.1;
            const seconds = 30;
            autoRefresh(seconds, `/admin/funds`, 'funds');
            autoRefresh(seconds, `/admin/user`, 'users');
            autoRefresh(seconds, `/admin/user/viewusertransactions`,'transactions');
            // load users transactions
            // load(`/transactions/${id}`, 'transactions');
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    return (
        <div>
            <UpdateAvailable />
            <Router>
                {currentUser && <Sidebar />}
                <ScrollToTop />
                <div className={currentUser ? "md:ml-64" : ""}>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/dashboard" />
                        </Route>
                        <Route exact path="/login" component={Login} />
                        
                        <PrivateRoute exact path="/dashboard" component={Dashboard} />

                        <PrivateRoute exact path="/users" component={Users} />
                        <PrivateRoute exact path="/users/:selectedparam" component={Users} />
                        <PrivateRoute exact path="/users/verify/:id" component={Verify} />
                        <PrivateRoute exact path="/users/add" component={AddUser} />

                        <PrivateRoute exact path="/funds" component={Funds} />
                        <PrivateRoute exact path="/funds/add" component={AddFund} />
                        <PrivateRoute exact path="/funds/edit/:id" component={EditFund} />

                        <PrivateRoute exact path="/transactions" component={Transactions} />
                        <PrivateRoute exact path="/transactions/:selectedparam" component={Transactions} />

                        <PrivateRoute exact path="/profile" component={Profile} />
                        <PrivateRoute exact path="/profile/update-email" component={UpdateEmail} />
                        <PrivateRoute exact path="/profile/change-password" component={ChangePassword} />

                        <PrivateRoute exact path="/help" component={() => <div>help</div>} />

                        <Route component={NotFound} />
                    </Switch>
                    <div className="h-10" />
                </div>
            </Router>
            <Alert />
        </div>
    );
}

export default App;
