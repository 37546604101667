import {vbApi} from 'api/vb';

import {ApproveUser} from 'types'; 

// ADMIN 

// TODO: Backend returning empty array
export async function getKycDocs(id: number) {
    try {
        const res = await vbApi.get(`/admin/user/kycdocs/${id}`);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

// IMPLEMENTED - WORKING
export async function approveUserKyc(body: ApproveUser, id: number) {
    try {
        const res = await vbApi.put(`/admin/user/approveuserkyc/${id}`, body);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}
