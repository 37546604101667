import {useRef} from 'react';

import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";
import {Link} from 'react-router-dom';

export default function Fab({color, icon, size, tooltip, link, onClick, position}) {
    const tooltipRef = useRef();

    const positionClasses = {
        "top-left": "top-5 left-5",
        "top-right": "top-5 right-5",
        "bottom-left": "bottom-5 right-5",
        "bottom-right": "bottom-5 right-5",
    };

    return (
        <div className={`fixed ${positionClasses[position]}`}>
            {link ? (
                <Link to={link}>
                    <Button
                        color={color}
                        buttonType="filled"
                        size={size}
                        rounded={true}
                        block={false}
                        iconOnly={true}
                        ripple="light"
                        ref={tooltipRef}
                    >
                        <Icon name={icon} size="sm" />
                    </Button>
                </Link>
            ) : (
                <Button
                    color={color}
                    buttonType="filled"
                    size={size}
                    rounded={true}
                    block={false}
                    iconOnly={true}
                    ripple="light"
                    ref={tooltipRef}
                    onClick={onClick && onClick}
                >
                    <Icon name={icon} size="sm" />
                </Button>
            )}

            <Tooltips placement="left" ref={tooltipRef}>
                <TooltipsContent>{tooltip}</TooltipsContent>
            </Tooltips>
        </div>
    )
}
