import {vbApi} from 'api/vb';

import {Fund} from 'types';

// ADMIN

// IMPLEMENTED - WORKING
export async function getFunds() {
    try {
        const res = await vbApi.get(`/admin/funds`);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

// NO NEED
export async function viewFund(id: number) {
    try {
        const res = await vbApi.get(`/admin/funds/viewfunds/${id}`);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

// IMPLEMENTED - HAVE NOT TESTED AS ONLY HAVE 1 FUND TO WORK WITH AND CANNOT ADD NEW ONES
export async function deleteFund(id: number) {
    try {
        const res = await vbApi.delete(`/admin/funds/deletefunds/${id}`);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

// TODO
// IMPLEMENTED - NOT WORKING
export async function addFund(body: Fund) {
    try {
        const res = await vbApi.post(`/admin/funds/addfund`, body);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

// TODO - GETTING 'A9' (DIDN\T EDIT FUND) RESPONSE FROM API
export async function editFund(body: Fund, id: number) {
    try {
        const res = await vbApi.put(`/admin/funds/editfund/${id}`, body);

        if(res) return res;

    } catch(err) {
        console.log(err);
    }
}

