import {useState} from 'react';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Input from '@material-tailwind/react/Input';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import { Paragraph } from '@material-tailwind/react';

import Modal from 'components/general/Modal';

import {Link, useHistory} from 'react-router-dom';

import {useAuth} from 'hooks/useAuth';

import SvgLogo from 'assets/svg-icons/Logo';

import { Helmet } from 'react-helmet';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState({});
    const [incorrectDetailsShow, setIncorrectDetailsShow] = useState(false);
    const {login} = useAuth();
    const history = useHistory();

    function onLoginClick() {
        let tempErrors = {};

        if(!email) {
            tempErrors.email = 'Please enter your email to continue';
        }

        if(!password) {
            tempErrors.password = 'Please enter your password to continue';
        }
        
        if(tempErrors && typeof tempErrors === 'object' && Object.keys(tempErrors).length > 0) {
            setError(tempErrors);

            return;
        }

        const isSuccessful = login(email, password);

        isSuccessful.then((value) => !value ? setIncorrectDetailsShow(true) : history.push('/'));
        
        return;
    }

    
    function clearError(key) {
        if (key && typeof key === 'string') {
            const tempErrors = error;

            tempErrors[key] = null;

            setError(tempErrors);
        }
    }

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Login - Venturbay</title>
        </Helmet>
        <div className="h-screen px-3 md:px-8 flex items-center justify-center flex-col bg-image">
            {/* <h3 className="font-normal text-4xl text-white">Log in to Venture Bay</h3> */}
            <div className="h-2" />
            <Card className="max-w-3xl text-center bg-primary">  
                <div className="w-full flex justify-center">
                    <SvgLogo className="w-40 h-auto sb-noclose mt-2"/>
                </div>
                <div className="h-2" />
                <Paragraph color="white">Welcome back, enter your email and password to access your account.</Paragraph>
                <CardBody>
                    <div className={`${error && error.email ? "mb-10" : "mb-4"} text-left`}>
                        <Input
                            outline={false}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            onFocus={() => clearError("password")}
                            type="text"
                            color="green"
                            
                            placeholder="Email"
                            size="regular"
                            error={error && error.email}
                        />
                    </div>

                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            onFocus={() => clearError("password")}
                            type="password"
                            color="green"
                            
                            placeholder="Password"
                            size="regular"
                            error={error && error.email}
                        />
                    </div>
                </CardBody>
                <CardFooter>
                    <Button
                        color="green"
                        className="w-full hover:bg-green hover:text-black hover:shadow-xl hover:pr-2"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                        onClick={onLoginClick}
                    >
                        Login
                        <Icon name="arrow_forward" size="sm" />
                    </Button>
                    <div className="h-5" />
                    {/* <Paragraph color="white">
                        Need assistance? <Link className="text-green" to="/register">Get help</Link>
                    </Paragraph> */}
                </CardFooter>

                <Modal
                    showModal={incorrectDetailsShow}
                    setShowModal={setIncorrectDetailsShow}
                    size="sm"
                >
                    <div className="p-5">
                        <h3 className="text-white font-normal text-xl mb-5">
                            Login unsuccessful
                            </h3>
                        <Paragraph color="white">
                            The email or password you entered is issncorrect or you haven't registered yet. Either{" "}
                            <Link className="text-green cursor-pointer text-base font-normal" to="/register">register</Link>{" "}or{" "}
                            <span className="text-green cursor-pointer font-normal" to="/register" onClick={() => setIncorrectDetailsShow(false)}>try again</span>.
                            </Paragraph>
                        <div className="h-5" />
                        <Button
                            color="green"
                            className="w-full"
                            buttonType="filled"
                            size="lg"
                            ripple="dark"
                            onClick={() => setIncorrectDetailsShow(false)}
                        >
                            Okay
                            </Button>
                    </div>
                </Modal>
            </Card>
        </div>
        </>
    );
}
