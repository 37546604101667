import Card from 'components/cards/Card';
import Icon from '@material-tailwind/react/Icon';
import { Link } from 'react-router-dom';

export default function Options2() {

    return (
        <>
            <div className="flex mb-5">
                <Link to="/funds" className="w-full">
                    <Card className="bg-green shadow-2xl cursor-pointer">
                        <div className="flex items-center justify-center flex-col h-full py-1">
                            <Icon name="trending_up" size="6xl" />
                            <h3>Funds</h3>
                        </div>
                    </Card>
                </Link>
                <Link to="/transactions" className="w-full ml-5">
                    <Card className="bg-green shadow-2xl cursor-pointer">
                        <div className="flex items-center justify-center flex-col h-full py-1">
                            <Icon name="sync_alt" size="6xl" />
                            <h3>Transactions</h3>
                        </div>
                    </Card>
                </Link>
            </div>
        </>
    );
}
