import {useEffect, useRef} from 'react';
import {store, useGlobalState} from 'state-pool';
import { useLocalStorage } from 'usehooks-ts';
// import _ from 'lodash';

import {User} from '../models/user';
import {Fund} from '../models/fund';
import {Transaction} from '../models/transaction';

import {vbApi} from '../api/vb';
import {useOffline} from './useOffline';
import {useAlert} from './useAlert';

type TimerReference = {timerReference: NodeJS.Timeout, key: string, interval: number, path: string}[];

store.setState('users', []);
store.setState('user', null);
store.setState('funds', []);
store.setState('fund', null);
store.setState('transactions', []);
store.setState('transaction', null);

export const useGlobal = () => {
    // user
    const [users, setUsers] = useGlobalState(`users`);
    const [user, setUser] = useGlobalState(`user`);
    const [cachedUser, setCachedUser] = useLocalStorage('user', null);
    const [cachedUsers, setCachedUsers] = useLocalStorage<User[] | null>('users', null);

    // fund
    const [funds, setFunds] = useGlobalState(`funds`);
    const [fund, setFund] = useGlobalState(`fund`);
    const [cachedFund, setCachedFund] = useLocalStorage('fund', null);
    const [cachedFunds, setCachedFunds] = useLocalStorage<Fund[] | null>('funds', null);

    // transaction
    const [transactions, setTransactions] = useGlobalState(`transactions`);
    const [transaction, setTransaction] = useGlobalState(`transaction`);
    const [cachedTransaction, setCachedTransaction] = useLocalStorage('transaction', null);
    const [cachedTransactions, setCachedTransactions] = useLocalStorage<Transaction[] | null>('transactions', null);

    // offline
    const {addToQueue,isOnline} = useOffline();

    // misc
    const timer = useRef<TimerReference>([]);
    const {newAlert} = useAlert();

    useEffect(() => {
        return cleanUp;
    }, []);

    async function load(path: string, key: string) {
        const online = isOnline();

        if(!online) {
            addToQueue('GET', path);

            loadCache();

            return;
        }
        // get key
        // set global state key with response
        try {
            const res = await vbApi.get(path);

            if(res && res.data) {
                setKey(key, res.data);
            }
        } catch(err: any) {

            console.log(err.status);
            //adds to network queue
            addToQueue('GET', path);
        }
    }

    function loadCache() {
        setUsers(cachedUsers);
        setUser(cachedUser);
        setFunds(cachedFunds);
        setFund(cachedFund);
        setTransactions(cachedTransactions);
        setTransaction(cachedTransaction);
    }

    function setKey(key: string, data: any) {
        switch(key) {
            case 'users': {
                setCachedUsers(data);
                setUsers(data);
                return;
            }
            case 'user': {
                setCachedUser(data);
                setUser(data);
                return;
            }
            case 'funds': {
                setCachedFunds(data);
                setFunds(data);
                return;
            }
            case 'fund': {
                setCachedFund(data);
                setFund(data);
                return;
            }
            case 'transactions': {
                setCachedTransactions(data);
                setTransactions(data);
                return;
            }
            case 'transaction': {
                setCachedTransaction(data);
                setTransaction(data);
                return;
            }
        }
    }

    async function refresh(path: string, key: string) {
        const online = isOnline();

        if(!online) {
            return;
        }
        // gets users
        // reset users with response
        try {
            const res = await vbApi.get(path);

            if(res && res.data) {
                setKey(key, res.data);
            }
        } catch {
            //adds to network queue
            addToQueue('GET', path);
        }
    }

    async function add(data: any, path: string, key?: string) {
        const online = isOnline();

        if(!online) {
            addToQueue('POST', path, data);
            //TODO
            newAlert('danger', 'No network connection', 'Data will be submitted when connection is restored');
            //show alert for no network
            return;
        }

        try {
            // adds new data
            await vbApi.post(path, data);

            // refreshes once complete
            if(key) refresh(path, key);
        } catch {
            addToQueue('POST', path, data);
            return;
        }
    }

    function autoRefresh(interval: number, path: string, key: string) {
        const online = isOnline();

        if(!online) return;
        // setInterval with a refresh rate
        // gets and sets key at interval with refresh function

        let timerReference = setInterval(() => {
            refresh(path, key);
        }, interval * 1000);

        timer.current = [...timer.current, {
            timerReference,
            key,
            interval,
            path
        }];
    }

    async function remove(id: string, path: string, key: string) {
        const online = isOnline();

        if(!online) {
            addToQueue('DELETE', `${path}/${id}`);
            //TODO
            newAlert('danger', 'No network connection', 'Item will be deleted when connection is restored');

            //show no network
            return;
        }

        try {
            // updates user with updated user object
            await vbApi.delete(`${path}/${id}`);

            // refreshes once complete
            refresh(path, key);
        } catch (err) {
            addToQueue('DELETE', `${path}/${id}`);
            return;
        }
    }

    async function commit(data: any, id: string, path: string, key: string) {
        const online = isOnline();

        if(!online) {
            addToQueue('PUT', `${path}/${id}`, data);
            newAlert('danger', 'No network connection', 'Item will be updated when connection is restored');

            // show no network
            return;
        }

        try {
            // updates data with updated data object
            await vbApi.put(`${path}/${id}`, data);

            // refreshes once complete
            refresh(path, key + 's');
        } catch (err) {
            addToQueue('PUT', `${path}/${id}`, data);
            return;
        }
    }

    function cleanUp() {
        if(timer && timer.current) {
            timer.current.forEach(({timerReference}) => {
                clearInterval(timerReference);
            });
        };
    }

    return {
        load,
        add,
        commit,
        refresh,
        autoRefresh,
        remove,
        store,
        users,
        user,
        funds,
        fund,
        transactions,
        transaction
    }
}


// UNUSED SYNC FUNCTIONALITY
    // useEffect(() => {
    //     syncUsers();
    //     syncFunds();
    //     syncTransactions();

    //     console.log('USERS GS',users)
    //     // return () => setCachedUser(null);

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [user, users, fund, funds, transaction, transactions]);

    // function syncUsers() {
    //     // update

    //     if(user && (cachedUser && typeof cachedUser === 'object' && !_.isEqual(user, cachedUser))) {
    //         commit(user, user.id, '/users', 'user');
    //     }

    //     if(user && !cachedUser) setCachedUser(user);

    //     if(users && !_.isEqual(users, cachedUsers)) {
    //         // add
    //         if(Array.isArray(cachedUsers) && users.length > cachedUsers.length) {
    //             add(users[users.length - 1], '/users', 'users');
    //         }

    //         // delete
    //         if(Array.isArray(cachedUsers) && users && (users.length > 0) && users.length < cachedUsers.length) {
    //             const removeNullValues = cachedUsers.filter(item => item);
    //             const result = removeNullValues.filter(item => item && (users.indexOf(item) === -1));

    //             result.map((item: User) => remove(String(item.id), '/users', 'users'));
    //         }

    //         //update
    //         if(Array.isArray(cachedUsers) && users && (users.length > 0) && users.length === cachedUsers.length) {
    //             const result = cachedUsers.filter((item: User, i: number) => !_.isEqual(item, users[i]));

    //             console.log('CHANGED', result);
    //             // result.map((u: User) => remove(String(u.id), '/users', 'users'));
    //         }
    //     }

    //     if((!users || users.length === 0) && !_.isEqual(users, cachedUsers)) setUsers(cachedUsers);
    // }

    // function syncFunds() {
    //     if(fund && (cachedFund && typeof cachedFund === 'object' && !_.isEqual(fund, cachedFund))) {
    //         commit(fund, fund.id, '/funds', 'fund');
    //     }

    //     if(fund && !cachedFund) setCachedFund(fund);

    //     if(funds && !_.isEqual(funds, cachedFunds)) {
    //         // add
    //         if(Array.isArray(cachedFunds) && funds.length > cachedFunds.length) {
    //             add(funds[funds.length - 1], '/funds', 'funds');
    //         }

    //         // delete
    //         if(Array.isArray(cachedFunds) && funds && (funds.length > 0) && funds.length < cachedFunds.length) {
    //             const removeNullValues = cachedFunds.filter(item => item);
    //             const result = removeNullValues.filter(item => item && (funds.indexOf(item) === -1));

    //             result.map((item: Fund) => remove(String(item.id), '/funds', 'funds'));
    //         }

    //         //update
    //         if(Array.isArray(cachedFunds) && funds && (funds.length > 0) && funds.length === cachedFunds.length) {
    //             const result = cachedFunds.filter((item: Fund, i: number) => !_.isEqual(item, funds[i]));

    //             console.log('CHANGED', result);
    //         }
    //     }

    //     if((!funds || funds.length === 0) && !_.isEqual(funds, cachedFunds)) setFunds(cachedFunds);
    // }

    // function syncTransactions() {
    //     if(transaction && (cachedTransaction && typeof cachedTransaction === 'object' && !_.isEqual(transaction, cachedTransaction))) {
    //         commit(transaction, transaction.id, '/transactions', 'transaction');
    //     }

    //     if(transaction && !cachedTransaction) setCachedTransaction(transaction);

    //     if(transactions && !_.isEqual(transactions, cachedTransactions)) {
    //         // add
    //         if(Array.isArray(cachedTransactions) && transactions.length > cachedTransactions.length) {
    //             add(transactions[transactions.length - 1], '/transactions', 'transactions');
    //         }

    //         // delete
    //         if(Array.isArray(cachedTransactions) && transactions && (transactions.length > 0) && transactions.length < cachedTransactions.length) {
    //             const removeNullValues = cachedTransactions.filter(item => item);
    //             const result = removeNullValues.filter(item => item && (transactions.indexOf(item) === -1));

    //             result.map((item: Transaction) => remove(String(item.id), '/transactions', 'transactions'));
    //         }

    //         //update
    //         if(Array.isArray(cachedTransactions) && transactions && (transactions.length > 0) && transactions.length === cachedTransactions.length) {
    //             const result = cachedTransactions.filter((item: Transaction, i: number) => !_.isEqual(item, transactions[i]));

    //             console.log('CHANGED', result);
    //         }
    //     }

    //     if((!transactions || transactions.length === 0) && !_.isEqual(transactions, cachedTransactions)) setTransactions(cachedTransactions);
    // }
