import Modal from 'components/general/Modal';
import VerifyLevelOne from 'components/verify/VerifyLevelOne';
import VerifyLevelTwo from 'components/verify/VerifyLevelTwo';
import VerifyLevelThree from 'components/verify/VerifyLevelThree';

export default function VerificationModal({showVerificationModal, setShowVerificationModal, level, docs, user, misc}) {

    return (
        <Modal 
            showModal={showVerificationModal}
            setShowModal={setShowVerificationModal}
            title={`Verify level ${level}`}
            size="lg"
        >   
            {level === 0 && <VerifyLevelOne misc={misc} docs={docs} user={user} setShowModal={setShowVerificationModal} />}
            {level === 1 && <VerifyLevelOne misc={misc} docs={docs} user={user} setShowModal={setShowVerificationModal} />}
            {level === 2 && <VerifyLevelTwo misc={misc} docs={docs} user={user} setShowModal={setShowVerificationModal} />}
            {level === 3 && <VerifyLevelThree misc={misc} docs={docs} user={user} setShowModal={setShowVerificationModal} />}
        </Modal>
    )
}
