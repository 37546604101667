import {useState} from 'react';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
// import Checkbox from '@material-tailwind/react/Checkbox';
import Paragraph from '@material-tailwind/react/Paragraph';

import Modal from 'components/general/Modal';

import { Heading3 } from '@material-tailwind/react';

import { useGlobalState } from 'state-pool';

export default function ProfileDetails() {
    const [showEnable2faConfirm, setShowEnable2faConfirm] = useState(false);
    const [twoFaEnabled, setTwoFaEnabled] = useState(false);
    const [showEmailNotificationsModal, setShowEmailNotificationsModal] = useState(false);
    const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(false);

    const [currentUser] = useGlobalState('currentUser');

    return (
        <>
            <Heading3>Info</Heading3>
            <Card className="mb-2 bg-primary">
                <CardBody>
                    <div className="relative h-10">
                        <div className="inline left">
                            <h5 className="text-gray-500 font-normal tracking-wide text-xs">Legal name</h5>
                            <span className="text-sm text-white font-normal">{currentUser && currentUser.name_first} {currentUser && currentUser.name_last}</span>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Card className="mb-2 bg-primary">
                <CardBody>
                    <div className="relative h-10">
                        <div className="inline left">
                            <h5 className="text-gray-500 font-normal tracking-wide text-xs">Admin Level</h5>
                            <span className="text-sm text-white font-normal">{currentUser?.role_id == 1 ? 'Approvals' : 'None'}</span>
                        </div>
                    </div>
                </CardBody>
            </Card>
            {/* <Card className="mb-2 bg-primary">
                <CardBody>
                    <div className="relative h-10">
                        <div className="inline left">
                            <h5 className="text-gray-500 font-normal tracking-wide text-xs">Verification status</h5>
                            <span className="text-sm text-white font-normal">Level 1 - Verified</span>
                        </div>
                        <Link to="/verify">
                            <Button color="green" className="inline float-right hover:bg-transparent" buttonType="outline">
                                Level 2
                                <Icon name="arrow_forward" color="black" size="sm" />
                            </Button>
                        </Link>
                    </div>
                </CardBody>
            </Card> */}
            <Modal 
                showModal={showEnable2faConfirm}
                setShowModal={setShowEnable2faConfirm}
                title={!twoFaEnabled ? "Enable 2 factor authentication" : "Disable 2 factor authentication"}
                size="regular"
            >
                <Paragraph color="white">
                    {twoFaEnabled ? 
                        `Are you sure you want to disable 2 factor authentication?` :
                        `Enabling 2 factor authentication helps secure your acount. Each time you log in, we will email you a 4 digit code which you will enter as an extra step after email & password validation.`
                    }
                </Paragraph>
                <Button 
                    onClick={() => {
                        setShowEnable2faConfirm(false);
                        setTimeout(() => {
                            setTwoFaEnabled(!twoFaEnabled);
                        }, 500);
                    }} 
                    color={!twoFaEnabled ? "green" : "orange"} 
                    className="inline float-right border-primary" 
                    buttonType="fill"
                >
                    {twoFaEnabled ? "Disable" : "Enable"}
                </Button>
                <Button 
                    onClick={() => setShowEnable2faConfirm(false)} 
                    color="red" 
                    className="inline mr-2 float-right border-primary hover:bg-transparent hover:bg-red hover:text-white" 
                    buttonType="outline"
                    ripple="dark"
                >
                    Cancel
                </Button>
            </Modal>
            <Modal 
                showModal={showEmailNotificationsModal}
                setShowModal={setShowEmailNotificationsModal}
                title={!emailNotificationsEnabled ? "Enable login notifications" : "Disable login notifications"}
                size="regular"
            >
                <Paragraph>
                    {emailNotificationsEnabled ? 
                        `Are you sure you want to disable login notifications?` :
                        `Enabling login notifications helps secure your acount. Each time a log in on your account occurs, we will email you. If it was not you, you can flag the activity in the email. We will immediately end that session and send you a reset password email, weher you'll be able to regain access to you account.`
                    }
                </Paragraph>
                <Button 
                    onClick={() => {
                        setShowEmailNotificationsModal(false);
                        setTimeout(() => {
                            setEmailNotificationsEnabled(!emailNotificationsEnabled);
                        }, 500);
                    }} 
                    color={!emailNotificationsEnabled ? "green" : "orange"} 
                    className="inline float-right border-primary" 
                    buttonType="fill"
                >
                    {emailNotificationsEnabled ? "Disable" : "Enable"}
                </Button>
                <Button 
                    onClick={() => setShowEmailNotificationsModal(false)} 
                    color="red" 
                    className="inline mr-2 float-right border-primary" 
                    buttonType="outline"
                >
                    Cancel
                </Button>
            </Modal>
        </>
    );
}
