import Button from '@material-tailwind/react/Button';

import Modal from 'components/general/Modal';

export default function DeleteFundConfirmationModa({showModal, setShowModal, name, deleteFund}) {

    async function onDeleteClick() {
        await deleteFund();

        setShowModal(false);
    }

    return (
        <Modal 
            showModal={showModal}
            setShowModal={setShowModal}
            title={`Are you sure you want to delete fund ${name}`}
            size="sm"
        >   
        <div className="w-max">
            <div className="h-10" />
            <Button 
                onClick={() => setShowModal(false)} 
                className="inline float-right border-primary" 
                buttonType="fill"
            >
                Cancel
            </Button>
            <Button 
                onClick={onDeleteClick} 
                color="red" 
                className="inline mr-2 hover:bg-red-500 hover:text-white float-right border-primary" 
                buttonType="outline"
            >
                Delete
            </Button>
        </div>
        </Modal>
    )
}
