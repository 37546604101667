import {useState, useEffect} from 'react';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Input from '@material-tailwind/react/Input';
import Textarea from '@material-tailwind/react/Textarea';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

import DropdownInput from 'components/inputs/DropdownInput';
import { useGlobal } from 'hooks/useGlobalState';

import { useGlobalState } from 'state-pool';
import { isValidJson } from 'utils';
import { useHistory } from 'react-router';

import {addWeeks} from 'utils';

import {format} from 'date-fns';

import { Helmet } from 'react-helmet';

const TYPE_OPTIONS = [
    {
        label: 'Crypto',
        value: 'crypto'
    },
    {
        label: 'Bitcoin',
        value: 'bitcoin'
    },
];

const REBALANCE_OPTIONS = [
    {
        label: 'Daily',
        value: 0
    },
    {
        label: 'Weekly',
        value: 1
    },
    {
        label: 'Every month',
        value: 2
    },
    {
        label: 'Every 6 months',
        value: 3
    },
    {
        label: 'Yearly',
        value: 4
    },
];

export default function FundEdit() {
    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [movement, setMovement] = useState(null);
    const [minAmount, setMinAmount] = useState(null);
    const [description, setDescription] = useState('');
    const [usersLimit, setUsersLimit] = useState(null);
    const [spreadQuantity, setSpreadQuantity] = useState(null);
    const [spreadItems, setSpreadItems] = useState('');
    const [rebalanceFrequency, setRebalanceFrequency] = useState(null);
    const [rebalanceDays, setRebalanceDays] = useState(null);
    const [adminFeeIn, setAdminFeeIn] = useState(null);
    const [adminFeeOut, setAdminFeeOut] = useState(null);
    const [fund, setFund] = useGlobalState('fund');
    const {commit, refresh} = useGlobal();

    const history = useHistory();

    useEffect(() => {

        if(fund) {
            if(fund.name) setName(fund.name);
            if(fund.type) {
                const display = TYPE_OPTIONS.find(o => o.value === fund.type);

                if(display) setType(display);
            }
            if(fund.movement) setMovement(fund.movement);
            if(fund.min_amount) setMinAmount(fund.min_amount);
            if(fund.misc && isValidJson(fund.misc) && JSON.parse(fund.misc).description) {
                const fundDescription = JSON.parse(fund.misc).description
                setDescription(fundDescription);
            }
            if(fund.users_limit) setUsersLimit(fund.users_limit);
            if(fund.spread_quantity) setSpreadQuantity(fund.spread_quantity);
            if(fund.spread_items) setSpreadItems(fund.spread_items);
            if(fund.rebalance_frequency) {
                const display = REBALANCE_OPTIONS.find(o => o.value === Number(fund.rebalance_frequency));

                if(display) setRebalanceFrequency(display);
            }
            if(fund.rebalance_days) setRebalanceDays(fund.rebalance_days);
            if(fund.admin_fee_in_multi) setAdminFeeIn(fund.admin_fee_in_multi);
            if(fund.admin_fee_out_multi) setAdminFeeOut(fund.admin_fee_out_multi);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onSubmitClick() {
        const rebalanceNext = addWeeks(1);

        const body = {
            type: type && type.value,
            name,
            movement: Number(movement),
            min_amount: Number(minAmount),
            misc: {
                description,
            },
            users_limit: Number(usersLimit),
            spread_quantity: Number(spreadQuantity),
            spread_items: Number(spreadItems),
            rebalance_last: format(new Date(), 'yyyy-MM-dd'),
            rebalance_next: format(new Date(rebalanceNext), 'yyyy-MM-dd'),
            rebalance_frequency: rebalanceFrequency && rebalanceFrequency.value,
            rebalance_days: Number(rebalanceDays),
            admin_fee_in_multi: Number(adminFeeIn),
            admin_fee_out_multi: Number(adminFeeOut),
            admin_fees_total: 2
        };

        if(fund && fund.id) commit(body, `editfund/${fund.id}`, `/admin/funds`, 'fund');

        setFund(null);

        history.push('/funds');
        refresh('/admin/funds', 'funds');
    }

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Edit Funds - Venturbay</title>
        </Helmet>
        
        <div className="h-auto px-3 md:px-8 flex items-center justify-center flex-col">
            <div className="h-20" />

            <Card className="max-w-3xl text-center bg-primary">  
                <div className="h-2" />
                <CardBody>
                    <div className="w-full text-left">
                        <h3 className="font-normal text-4xl text-white">Edit {fund && fund.name}</h3>
                        <div className="h-2" />
                    </div>
                    <div className="h-10" />
                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={name}
                            onChange={e => setName(e.target.value)}
                            type="text"
                            color="green"
                            placeholder="Name"
                            size="regular"
                        />
                    </div>

                    <div className="mb-4 text-left">
                        <DropdownInput
                            value={type}
                            setValue={setType}
                            options={TYPE_OPTIONS}
                            color="green"
                            placeholder="Select fund type"
                            block
                        />
                    </div>

                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={movement}
                            onChange={e => setMovement(e.target.value)}
                            type="number"
                            color="green"
                            placeholder="Movement"
                            size="regular"
                        />
                    </div>

                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={minAmount}
                            onChange={e => setMinAmount(e.target.value)}
                            type="number"
                            color="green"
                            placeholder="Minimum buy in"
                            size="regular"
                        />
                    </div>

                    <div className="mb-4 mt-10 text-left">
                        <Textarea
                            outline={false}
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            type="number"
                            color="green"
                            placeholder="Description"
                            size="regular"
                        />
                    </div>

                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={usersLimit}
                            onChange={e => setUsersLimit(e.target.value)}
                            type="number"
                            color="green"
                            placeholder="Users limit"
                            size="regular"
                        />
                    </div>

                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={spreadQuantity}
                            onChange={e => setSpreadQuantity(e.target.value)}
                            type="number"
                            color="green"
                            placeholder="Spread quantity"
                            size="regular"
                        />
                    </div>

                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={spreadItems}
                            onChange={e => setSpreadItems(e.target.value)}
                            type="number"
                            color="green"
                            placeholder="Spread items"
                            size="regular"
                        />
                    </div>

                    <div className="mb-4 text-left">
                        <DropdownInput 
                            value={rebalanceFrequency}
                            setValue={setRebalanceFrequency}
                            options={REBALANCE_OPTIONS}
                            color="green"
                            placeholder="Rebalance frequency"
                            block
                        />
                    </div>

                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={rebalanceDays}
                            onChange={e => setRebalanceDays(e.target.value)}
                            type="number"
                            color="green"
                            placeholder="Rebalance days"
                            size="regular"
                        />
                    </div>

                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={adminFeeIn}
                            onChange={e => setAdminFeeIn(e.target.value)}
                            type="number"
                            color="green"
                            placeholder="Admin fee in"
                            size="regular"
                        />
                    </div>

                    <div className="mb-4 text-left">
                        <Input
                            outline={false}
                            value={adminFeeOut}
                            onChange={e => setAdminFeeOut(e.target.value)}
                            type="number"
                            color="green"
                            placeholder="Admin fee out"
                            size="regular"
                        />
                    </div>

                </CardBody>
                <CardFooter>
                    <Button
                        color="green"
                        className="w-full hover:bg-green hover:text-white hover:shadow-xl hover:pr-2"
                        buttonType="outline"
                        size="lg"
                        ripple="dark"
                        onClick={onSubmitClick}
                    >
                        Update
                        <Icon name="arrow_forward" size="sm" />
                    </Button>
                    <div className="h-5" />
                </CardFooter>
            </Card>
        </div></>
    );
}
