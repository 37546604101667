// import BalanceInfo from 'components/dashboard/BalanceInfo';
import Options from 'components/dashboard/Options';
import Options2 from 'components/dashboard/Options2';
import OptionsWidget from 'components/dashboard/OptionsWidget';
import { Helmet } from 'react-helmet';

export default function Dashboard() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard - Venturbay</title>
            </Helmet>
            <div className="bg-gray-900 px-3 md:px-8 h-40" />

            <div className="px-3 md:px-8 -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 lg:gap-5 2xl:grid-cols-6">
                        <div className="2xl:col-start-1 2xl:col-end-4 mb-141">
                            <OptionsWidget />
                            {/* <BalanceInfo /> */}
                        </div>
                        <div className="2xl:col-start-4 2xl:col-end-7 mb-14">
                            <Options />
                            <Options2 />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
