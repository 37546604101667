import {useRef} from 'react';
import {Link} from 'react-router-dom';

import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
// import NavbarInput from '@material-tailwind/react/NavbarInput';
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";

// import Dropdown from 'components/inputs/Dropdown';
import NavigationControls from 'components/buttons/NavigationControls';

import {useGlobalState} from 'state-pool';

export default function AdminNavbar({ showSidebar, setShowSidebar }) {
    const [currentUser] = useGlobalState('currentUser');

    const addIconInfoRef = useRef();

    // const SUPPORT_DROPDOWN_OPTIONS = [
    //     {
    //         link: "/some-link",
    //         label: "FAQ"
    //     },
    //     {
    //         link: "/some-link",
    //         label: "Guides"
    //     },
    //     {
    //         link: "/some-link",
    //         label: "Contact us"
    //     },
    // ];

    return (
        <nav className="bg-primary md:ml-64 py-6 px-3">
            <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-0">
                <div className="flex justify-between items-center w-full">
                    <div className="flex items-center">
                        <NavigationControls className="mr-5" />
                        <h4 className="uppercase text-white text-sm tracking-wider">
                            <span className="font-normal">{currentUser && currentUser.name_first && currentUser.name_first}{" "}{currentUser && currentUser.name_last && currentUser.name_last}</span> admin
                        </h4>
                        {/* <Link to="/deposit">
                            <Button
                                color="transparent"
                                buttonType="link"
                                size="lg"
                                iconOnly
                                rounded
                                ripple="light"
                                onClick={() => {}}
                                ref={addIconInfoRef}
                            >
                                    <Icon name="help" size="2xl" color="white" />
                            </Button>
                        </Link>
                        <Tooltips placement="top" ref={addIconInfoRef}>
                            <TooltipsContent>Help info on user type etc.</TooltipsContent>
                        </Tooltips> */}
                    </div>

                    <div className="flex">
                        {/* <div className="hide-sm">
                            <NavbarInput placeholder="Search" />
                        </div> */}

                        {/* <div className="-mr-4 ml-6 hidden md:block">
                            <Dropdown
                                color="gray"
                                iconOnly
                                buttonText={
                                    <div className="w-10 h-10 shadow-md flex" style={{borderRadius: '50%', alignItems: 'center', justifyContent: 'center'}}>
                                        <Icon name="help" size="2xl" color="white" />
                                    </div>
                                }
                                rounded
                                style={{
                                    padding: 0,
                                    color: 'transparent',
                                }}
                                options={SUPPORT_DROPDOWN_OPTIONS}
                                type="link"
                            />
                        </div> */}
                    </div>
                </div>
                <div className="md:hidden">
                    <Button
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        iconOnly
                        rounded
                        ripple="light"
                        onClick={() => setShowSidebar('right-0')}
                    >
                        <Icon name="menu" size="2xl" color="white" />
                    </Button>
                    <div
                        className={`absolute top-2 md:hidden ${
                            showSidebar === 'right-0' ? 'right-59' : 'hidden'
                        } z-50 transition-all duration-300`}
                    >
                        <Button
                            color="transparent"
                            buttonType="link"
                            size="lg"
                            iconOnly
                            rounded
                            ripple="light"
                            className="text-white"
                            onClick={() => setShowSidebar('-right-64')}
                        >
                            <Icon name="close" size="2xl" color="black" />
                        </Button>
                    </div>
                </div>
            </div>
        </nav>
    );
}
