import Button from '@material-tailwind/react/Button';
import Paragraph from '@material-tailwind/react/Paragraph';

import {approveUserKyc} from 'routes/kycRoutes';

import UserDetails from 'components/verify/UserDetails';
import { useGlobal } from 'hooks/useGlobalState';

export default function VerifyLevelOne({docs, user, setShowModal, misc}) {
    const {refresh} = useGlobal();

    async function onVerifyClick() {

        if(user && user.id) {
            const body = {
                kyc_current:  1
            };

            const isSuccessful = await approveUserKyc(body, user.id);

            if(isSuccessful) {
                refresh('/admin/user', 'users');
                setShowModal(false);
            }
        }
    }

    function onDenyClick() {
        setShowModal(false);
    }

    return (
        <div className="w-max">
            <div className="w-full mb-10">
                <h3 className="text-white font-bold mb-5">User details</h3>
                <UserDetails user={{...user, ...misc, ...docs}} />
            </div>

            <div className="h-10" />
            <Button 
                onClick={onVerifyClick} 
                className="inline float-right border-primary" 
                buttonType="fill"
            >
                Verify
            </Button>
            <Button 
                onClick={onDenyClick} 
                color="red" 
                className="inline mr-2 hover:bg-red-500 hover:text-white float-right border-primary" 
                buttonType="outline"
            >
                Deny verification
            </Button>
        </div>
    )
}
