import {Link} from 'react-router-dom';

import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';

import { Heading3 } from '@material-tailwind/react';

export default function ProfileDetails() {

    return (
        <>
            <Heading3>Account Details</Heading3>
            {/* TODO: may enable below at future stage */}
            {/* <Link to="/profile/banking-details">
                <Card className="mb-2 transition cursor-pointer group bg-primary">
                    <CardBody>
                        <div className="relative h-5">
                            <div className="inline left">
                                <span className="text-sm text-gray-900 font-normal text-white">Banking details</span>
                            </div>
                            <Button
                                color=""
                                className="inline float-right -mt-1 group-hover:pl-4"
                                buttonType="link"
                                size="sm"
                                iconOnly
                                rounded
                                ripple="light"
                                onClick={() => {}}
                            >
                                    <Icon name="arrow_forward" size="2xl" color="white" />
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Link> */}
            <Link to="/profile/update-email">
                <Card className="mb-2 transition cursor-pointer group bg-primary">
                    <CardBody>
                        <div className="relative h-5">
                            <div className="inline left">
                                <span className="text-sm text-gray-900 font-normal text-white">Update email address</span>
                            </div>
                            <Button
                                color=""
                                className="inline float-right -mt-1 group-hover:pl-4"
                                buttonType="link"
                                size="sm"
                                iconOnly
                                rounded
                                ripple="light"
                                onClick={() => {}}
                            >
                                    <Icon name="arrow_forward" size="2xl" color="white" />
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Link>
            <Link to="/profile/change-password">
                <Card className="mb-2 transition cursor-pointer group bg-primary">
                    <CardBody>
                        <div className="relative h-5">
                            <div className="inline left">
                                <span className="text-sm text-gray-900 font-normal text-white">Change password</span>
                            </div>
                            <Button
                                color=""
                                className="inline float-right -mt-1 group-hover:pl-4"
                                buttonType="link"
                                size="sm"
                                iconOnly
                                rounded
                                ripple="light"
                                onClick={() => {}}
                            >
                                    <Icon name="arrow_forward" size="2xl" color="white" />
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Link>
        </>
    );
}
