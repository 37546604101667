import FundsTable from 'components/funds/FundsTable';
import Fab from 'components/buttons/Fab';
import { Helmet } from 'react-helmet';

export default function Funds() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Funds - Venturbay</title>
            </Helmet>
            <div className="px-3 md:px-8 h-auto mt-24 relative">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <FundsTable />
                        <Fab 
                            icon="add" 
                            color="gray" 
                            position="bottom-right" 
                            size="lg" 
                            tooltip="Add fund"
                            link="/funds/add"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
