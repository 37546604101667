import {useRef} from 'react';

import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Paragraph from '@material-tailwind/react/Paragraph';
import AddressDetails from 'components/verify/AddressDetails';
import { useGlobal } from 'hooks/useGlobalState';
import {approveUserKyc} from 'routes/kycRoutes';

export default function VerifyLevelThree({docs, user, misc, setShowModal}) {
    const {refresh} = useGlobal();
    const downloadRefHidden = useRef();

    const proofOfAddress = docs && docs[2];

    async function onVerifyClick() {

        if(user && user.id) {
            const body = {
                kyc_current: 3
            };

            const isSuccessful = await approveUserKyc(body, user.id);

            if(isSuccessful) {
                refresh('/admin/user', 'users');
                setShowModal(false);
            }
        }
    }

    function onDenyClick() {
        setShowModal(false);
    }

    return (
        <div className="w-max">
            <div className="gs-12 my-10">
                <div className="gs-1-2">
                    <h3 className="text-white font-bold mb-5">Address</h3>
                    <AddressDetails address={misc && misc.physical_address} />
                </div>
                <div className="gs-2-2">
                    <h3 className="text-white font-bold mb-5">Proof of address</h3>
                    <div onClick={() => downloadRefHidden && downloadRefHidden.current && downloadRefHidden.current.click()}  className="h-full w-full p-5 border-white border-2 rounded-lg flex items-center justify-center flex-col hover:bg-white hover:bg-opacity-20 transition cursor-pointer">
                        <Icon name="download" color="white" size="7xl" />
                        <p className="text-white font-xs mt-2">proof_of_address.pdf</p>
                    </div>
                    <a href={proofOfAddress && proofOfAddress.base64} className="invisible h-0" ref={downloadRefHidden} download={proofOfAddress && `vb_verify_${user.name_first + '_' + user.name_last + '_proof_of_address.' + proofOfAddress.extension}`}>Download File</a>
                </div>
            </div>
            
            <div className="h-10"></div>
            <Button 
                onClick={onVerifyClick} 
                className="inline float-right border-primary" 
                buttonType="fill"
            >
                Verify
            </Button>
            <Button 
                onClick={onDenyClick} 
                color="red" 
                className="inline mr-2 hover:bg-red-500 hover:text-white float-right border-primary" 
                buttonType="outline"
            >
                Deny verification
            </Button>
        </div>
    )
}
