import { Component } from "react";

import Icon from '@material-tailwind/react/Icon';
import Button from '@material-tailwind/react/Button';


export default class InstallButton extends Component {
    constructor() {
        super();
        this.state = {
            showButton: false,
            showAlert: false,
        };
        this.onInstallBtnClicked = this.onInstallBtnClicked.bind(this);
    }

    componentDidMount() {

        // Detects if device is in standalone mode
        const isInStandaloneMode = () =>
            "standalone" in window.navigator && window.navigator.standalone;

        if (this.isIos() && !isInStandaloneMode()) {
            this.setState({
                showButton: true
            });
        }

        window.addEventListener("beforeinstallprompt", (e) => {
            e.preventDefault();
            this.deferredPrompt = e;
            this.setState({ showButton: true });
        });
    }

    // Detects if device is on iOS
    isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    };

    onInstallBtnClicked = () => {
        // Detects if device is in standalone mode
        const isInStandaloneMode = () =>
            "standalone" in window.navigator && window.navigator.standalone;

        // Checks if should display install popup notification:
        if (this.isIos() && !isInStandaloneMode()) {
            this.setState({ showAlert: true });
        }

        this.setState({ showButton: false });
        if (this.deferredPrompt) {
            this.deferredPrompt.prompt();

            // Wait for the user to respond to the prompt
            this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === "accepted") {

                } else {

                }
                this.deferredPrompt = null;
            });
        }
    };

    render() {
        return (
            <>
                {this.state.showButton && (
                    <li>
                        <Button 
                            color="" 
                            className={`border-orange text-orange hover:text-white hover:bg-orange w-full mb-2 ${this.props.className}`}
                            buttonType="outline"
                            id="install"
                            onClick={this.onInstallBtnClicked}
                        >
                            <Icon name="download" size="2xl" />
                            Install
                        </Button>
                    </li>
                )}
            </>
        );
    }
}
