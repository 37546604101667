import {useEffect, useState} from 'react';
import decode from 'jwt-decode';
import {useHistory} from 'react-router-dom';
import {store, useGlobalState} from 'state-pool';

import {useOffline} from 'hooks/useOffline';
import {useAlert} from 'hooks/useAlert';
import {vbApi} from 'api/vb';
import { useLocalStorage } from 'usehooks-ts';

store.setState("currentUser", null);

export const useAuth = () => {
    const [currentUser, setCurrentUser] = useGlobalState(`currentUser`);
    const [persistedUser, setPersistedUser] = useLocalStorage('currentUser', null);
    const [userPassword, setUserPassword] = useState()
    const {isOnline, addToQueue} = useOffline();
    const {newAlert} = useAlert();

    const history = useHistory();

    useEffect(() => {
        if(!currentUser && persistedUser && typeof persistedUser === 'object') {

            // @ts-ignore /this is an object type hence the typof check above ?????!!!
            setCurrentUser({...persistedUser});
        }

        if(!persistedUser) {
            setCurrentUser(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getUserDetails(decodedJwt?: {id: number, firstname: string, lastname: string}) {
        const res = await vbApi.get(`/user/getuser`);

        if(typeof res === 'object') {

            if(res.data) {
                let userData = {...currentUser, ...res.data};

                if(decodedJwt) {
                    userData = {...userData, ...decodedJwt};
                }

                setCurrentUser({...userData});
                setPersistedUser({...userData});

                return true;
            } else {
                let userData = {...currentUser, ...res};

                if(decodedJwt) {
                    userData = {...userData, ...decodedJwt};
                }

                setCurrentUser({...userData});
                setPersistedUser({...userData});

                return true;
            }
        }

        return false;
    }
    
    async function logout() {
        const accessToken = localStorage.getItem('access-token');
        const refreshToken = localStorage.getItem('refresh-token');

        if(refreshToken && typeof refreshToken === 'string' && accessToken && typeof accessToken === 'string') {
            await vbApi.delete('/authO/logout', {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    token: refreshToken
                }
            });
            setCurrentUser(null);
            localStorage.clear();
        }
        
        history.push('/login');
    }

    async function login(email: string, password: string) {
        
        try {
            const res = await vbApi.post('/authO/login', {
                email,
                password
            });

            if(res && res.status === 200 && res.data.accessToken) {
                const {accessToken, refreshToken} = res.data;

                localStorage.setItem('access-token', accessToken);
                localStorage.setItem('refresh-token', refreshToken);
                
                const decoded: {id: number, firstname: string, lastname: string} = decode(accessToken);

                setCurrentUser(decoded);
                localStorage.setItem('currentUser', JSON.stringify(decoded));

                if (decoded) {
                    getUserDetails(decoded).then(_ => {
                        return true;
                    });
                };

                return true;
            } else {
                return false;
            }
        } catch(err) {
            return false;
        }
    }

    async function commit(data: any, path: string) {
        const online = isOnline();

        if(!online) {
            addToQueue('PUT', path, data);
            // TODO
            newAlert('danger', 'No network connection', 'Item will be updated when connection is restored');

            // show no network
            return;
        }

        try {
            // updates data with updated data object
            const isSuccessful = await vbApi.put(path, data);

            if(isSuccessful) {
                // refreshes users data once complete
                getUserDetails();

                return true;
            }
        } catch (err) {
            addToQueue('PUT', path, data);
            return;
        }
    }

    async function getAllUserDetails(id: number){
        try{
            const res = await vbApi.get(`/admin/user/getAllUserDetails/${id}`)
            if(res) {
                const {password} = res.data
                setUserPassword(password)
                return true
            }
            return
        }catch(e){
            console.log(e)
        }
    }

    return {
        logout,
        login,
        commit,
        currentUser,
        getUserDetails,
        getAllUserDetails,
        userPassword
    }
}


