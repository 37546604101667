import {useEffect, useState, useRef} from 'react';

import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { isValidJson } from 'utils';
import { useGlobalState } from 'state-pool';
import { format } from 'date-fns';
import { transactionStatus, userToWallet } from 'routes/transactionRoutes';
import { useGlobal } from 'hooks/useGlobalState';
import Pagination from 'components/general/Pagination';
import Popover from "@material-tailwind/react/Popover";
import PopoverContainer from "@material-tailwind/react/PopoverContainer";

import Modal from "components/general/Modal";
import Paragraph from '@material-tailwind/react/Paragraph';
import ModalFooter from "@material-tailwind/react/ModalFooter";

import {toFixed, bitorsat} from 'utils/js';

import {useAlert} from 'hooks/useAlert';

const PER_PAGE = 10;

export default function TransactionsTable({selected}) {
    const { refresh } = useGlobal();
    const [transactions] = useGlobalState('transactions');
    const [transactionsDisplay, setTransactionsDisplay] = useState();
    const [users] = useGlobalState('users');
    const [funds] = useGlobalState('funds');

    const [currentPage, setCurrentPage] = useState(0);
    const [numPages, setNumPages] = useState(1);
    const [showFrom, setShowFrom] = useState(0);
    const [showTo, setShowTo] = useState(PER_PAGE);
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [currentTransId, setCurrentTransId] = useState(0);
    const [userId, setUserId] = useState(0);
    const [isWalletTransfer, setIsWalletTranfer] = useState(false);

    const regex = new RegExp(/([13]|bc1)[A-HJ-NP-Za-km-z1-9]{27,34}/);
    const {newAlert} = useAlert();

    async function onApproveClick(transactionId, status, userUID){
        const body = { "id": transactionId, "status": status }
        if(isWalletTransfer){
            try{
                const success = await userToWallet(userUID, transactionId)
                if (success) {
                    refresh(`/admin/user/viewusertransactions`, 'transactions');
                    return
                }
                return
            }catch(e) {

            }
        }
        if (transactionId !== null && typeof transactionId !== undefined && status !== null && typeof status !== undefined) {
            const isSuccessful = await  transactionStatus(body)

            if (isSuccessful) {
                refresh(`/admin/user/viewusertransactions`, 'transactions');
                
            }
        }

        
    }

    useEffect(() => {
        if(!selected || selected === 'all' && transactions.results) {
            setTransactionsDisplay([...transactions?.results].sort().reverse());

            return;
        }

        const filteredTransactions = transactions && transactions.results && transactions.results.filter(transaction => transaction && transaction.misc && isValidJson(transaction.misc) && JSON.parse(transaction.misc)?.status === selected);
        try{
            if (filteredTransactions) {
                setTransactionsDisplay([...filteredTransactions].sort().reverse()); 
            }
           
        } catch(e) {
            
        }
        

    }, [selected, transactions]);

    useEffect(() => {
        setShowFrom(currentPage * PER_PAGE);
        setShowTo((currentPage * PER_PAGE) + PER_PAGE);
    }, [currentPage]);

    useEffect(() => {
        if(transactionsDisplay && Array.isArray(transactionsDisplay) && transactionsDisplay.length > 0) {
            const numtransactionsDisplay = transactionsDisplay.length;
            
            const tempNumPages = Math.ceil(numtransactionsDisplay / PER_PAGE);

            setNumPages(tempNumPages);
        }
    }, [transactionsDisplay]);

    const getTransactionDestination = (transaction) => funds && transaction && transaction.destination && transaction.destination.includes('fund') && transaction.destination.split(':')[1]  && funds.find(fund => fund.id === (Number(transaction.destination.split(':')[1]))) && funds.find(fund => fund.id === (Number(transaction.destination.split(':')[1]))).name;
    
    const onKeyClick = (destination) => {
        navigator.clipboard.writeText(destination);
        newAlert('success', 'Copied to clipboard', ' ')
    };

    return (
        <>
        <Card className="bg-primary">
            <CardHeader color="gray" contentPosition="left">
                <h2 className="text-white text-2xl">Transactions</h2>
            </CardHeader>
            <CardBody>
                <div className="overflow-x-auto">
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                    Date and time
                                </th>
                                <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                    Username
                                </th>
                                <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                    Type
                                </th>
                                <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                    Amount before fees
                                </th>
                                <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                    Fee
                                </th>
                                <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                    Amount after fees
                                </th>
                                <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                    Status
                                </th>
                                <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                    Destination
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactionsDisplay && Array.isArray(transactionsDisplay) && transactionsDisplay.length > 0 && transactionsDisplay.slice(showFrom, showTo).map((transaction, i) => (
                                <tr key={transaction.id}>
                                    <td className="border-b border-gray-200 align-middle text-white font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                        {/* TODO format below */}
                                        {transaction.created_at && format(new Date(transaction.created_at), 'dd-MM-yyyy HH:mm')}
                                    </td>
                                    <td className="border-b border-gray-200 align-middle text-white font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                        {/* TODO needs logic on destination to determine display string */}
                                        {transaction && transaction.user_id && users && (users.find(user => user.id === transaction.user_id))?.email || 'User not found'}
                                    </td>
                                    <td className="border-b border-gray-200 align-middle text-white font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                        {/* TODO needs logic on destination to determine display string */}
                                        {transaction.destination.includes('user') && transaction.src.includes('fund') && "Fund Payout"}
                                        {transaction.destination.includes('fund') && transaction.src.includes('user') && "Fund Deposit"}
                                        {regex.test(transaction.destination) && transaction.src.includes('user') ? 'Withdrawal' : regex.test(transaction.destination) && transaction.src == 'External' ? 'Deposit': ' '}

                                    </td>
                                    <td className="border-b border-gray-200 align-middle text-white font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                        {/* TODO convert to decimal w/ 2 points & add currency symbol */}
                                        {" "}{transaction.amount && bitorsat(transaction.amount)}
                                    </td>
                                    <td className="border-b border-gray-200 align-middle text-white font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                        {/* TODO convert to decimal w/ 2 points & add currency symbol */}
                                        {" "}{transaction.fee_total && bitorsat(transaction.fee_total)}
                                    </td>
                                    <td className="border-b border-gray-200 align-middle text-white font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                        {/* TODO convert to decimal w/ 2 points & add currency symbol */}
                                        {/* TODO double check below logic once clarified on fee variables */}
                                        {transaction.fee_total === 0 ? bitorsat(transaction.amount) : transaction.amount ? (bitorsat((transaction.amount + transaction.fee_total))) : ' ' }
                                    </td>
                                    <td className={`border-b border-gray-200 align-middle ${JSON.parse(transaction.misc)?.status === "success" ? "text-success capitalize" : "text-orange"} font-normal text-sm whitespace-nowrap px-2 py-4 text-left`}>
                                        {/* TODO logic based on is_confirmed number to display string */}
                                        {/* {transaction.is_confirmed === 1 ? 'Successful' : 'Declined'} */}
                                        {/* {transaction && transaction.misc && isValidJson(transaction.misc) && JSON.parse(transaction.misc).status && JSON.parse(transaction.misc).status} */}
                                        <div className="flex items-center">
                                            {transaction && transaction.misc && isValidJson(transaction.misc) && JSON.parse(transaction.misc)?.status === 'pending' ?
                                                <>
                                                    <span className=" ">
                                                        Pending
                                                    </span>

                                                    <Button
                                                        size="sm"
                                                        className="ml-2"
                                                        onClick={() => {
                                                            setCurrentTransId(transaction.id);
                                                            setShowApprovalModal(true);
                                                            setUserId(transaction.user_id)
                                                            setIsWalletTranfer(regex.test(transaction.destination) && transaction.src.includes('user'))
                                                        }}
                                                    >
                                                        Approve
                                                    </Button>
                                                </> : JSON.parse(transaction.misc)?.status}
                                        </div>
                                    </td>
                                    <td className={`border-b border-gray-200 align-middle text-white font-normal text-sm whitespace-nowrap px-2 py-4 text-left`}>
                                        {/* TODO logic based on is_confirmed number to display string */}
                                        {/* {"R"}{" "}{transaction.your_balance}{" "}<Icon color="green" name="arrow_upward" size="sm" /> */}
                                        {getTransactionDestination(transaction)}
                                        {transaction.destination && transaction.destination.includes('user') && "Personal"}
                                        {regex.test(transaction.destination) 
                                            && <>
                                                <span className="cursor-pointer text-white" onClick={() => onKeyClick(transaction.destination)}>{transaction.destination}</span>
                                            </>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
        <div className='flex w-100 justify-center'>
           <Pagination 
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                numPages={numPages}
            /> 
        </div>
        

        <Modal 
            showModal={showApprovalModal}
            setShowModal={setShowApprovalModal}
            size="sm"   
            title="Proceed with approval"
        >
            <Paragraph color="white">
                Are you sure you want to approve this transaction?
            </Paragraph>
                    
            <ModalFooter>
                <Button 
                    color="green"
                    buttonType="link"
                    onClick={() => setShowApprovalModal(false)}
                    ripple="dark"
                >
                    No
                </Button>

                <Button
                    color="green"
                    className="text-black"
                    onClick={() => {
                        onApproveClick(currentTransId, "success", userId);
                        setShowApprovalModal(false);
                    }}
                    ripple="light"
                >
                    Yes
                </Button>
            </ModalFooter>
        </Modal>
        </>
    );
}
