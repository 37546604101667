import Card from 'components/cards/Card';
import Icon from '@material-tailwind/react/Icon';
import { Link } from 'react-router-dom';

export default function Options() {

    return (
        <div className="flex mb-5">
            <Link to="/users" className="w-full">
                <Card className="bg-green shadow-2xl cursor-pointer">
                    <div className="flex items-center justify-center flex-col h-full py-1">
                        <Icon name="group" size="6xl" />
                        <h3>Users</h3>
                    </div>
                </Card>
            </Link>
            <Link to="/profile" className="w-full ml-5">
                <Card className="bg-green shadow-2xl cursor-pointer">
                    <div className="flex items-center justify-center flex-col h-full py-1">
                        <Icon name="person" size="6xl" />
                        <h3>Your Profile</h3>
                    </div>
                </Card>
            </Link>
        </div>
    );
}
