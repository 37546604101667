import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import Card from 'components/cards/Card';

import {isValidJson} from 'utils';

import {useGlobalState} from 'state-pool';

export default function OptionsWidget() {
    const [users] = useGlobalState('users');
    const [transactions] = useGlobalState('transactions');
    const [funds] = useGlobalState('funds');

    const [kycPending, setKycPending] = useState(0);
    const [transactionsPending, setTransactionsPending] = useState(0);

    useEffect(() => {
        if(users && Array.isArray(users) && users.length > 0) {
            let numKycPending = 0;

            users.forEach(user => {
                if(user.kyc_pending) {
                    numKycPending++;
                }
            });

            setKycPending(numKycPending);
        }

        if(transactions && transactions.results && Array.isArray(transactions.results) && transactions.results.length > 0) {
            let numTransactionsPending = 0;

            transactions.results.forEach(transaction => {
                if(transaction && transaction.misc && isValidJson(transaction.misc) && JSON.parse(transaction.misc).status === 'pending') {
                    numTransactionsPending++;
                }
            });

            setTransactionsPending(numTransactionsPending);
        }
    }, [users, transactions]);

    return (
        <>
            <div className="flex mb-5">
                <Link to="/users/pending" className="w-full">
                    <Card className="bg-red-500 shadow-2xl cursor-pointer">
                        <div className="flex items-center justify-center flex-col h-full py-1">
                            <h1 className="text-6xl">{kycPending}</h1>
                            <h3>Users pending KYC approval</h3>
                        </div>
                    </Card>
                </Link>
                <Link to="/transactions/pending" className="w-full ml-5">
                    <Card className="bg-red-500 shadow-2xl cursor-pointer">
                        <div className="flex items-center justify-center flex-col h-full py-1">
                        <h1 className="text-6xl">{transactionsPending}</h1>
                            <h3>Transactions pending approval</h3>
                        </div>
                    </Card>
                </Link>
            </div>
            <div className="flex mb-5">
                <Link to="/users" className="w-full">
                    <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                        <div className="flex items-center justify-center flex-col h-full py-1">
                            <h1 className="text-6xl text-white">{users && users.length}</h1>
                            <h3 className="text-white">Total Users</h3>
                        </div>
                    </Card>
                </Link>
                <Link to="/funds" className="w-full ml-5">
                    <Card className="bg-gray-500 shadow-2xl cursor-pointer">
                        <div className="flex items-center justify-center flex-col h-full py-1">
                        <h1 className="text-6xl text-white">{funds && funds.length}</h1>
                            <h3 className="text-white">Total Funds</h3>
                        </div>
                    </Card>
                </Link>
            </div>
        </>
    );
}
