import {useRef, useState} from 'react';

import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Paragraph from '@material-tailwind/react/Paragraph';
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";
import {approveUserKyc} from 'routes/kycRoutes';
import UserDetails from 'components/verify/UserDetails';
import { useGlobal } from 'hooks/useGlobalState';

export default function VerifyLevelTwo({docs, user, setShowModal, misc}) {
    const [flipped, setFlipped] = useState(false);
    const downloadRef = useRef();
    const downloadRefHidden0 = useRef();
    const downloadRefHidden1 = useRef();
    const downloadRefHidden3 = useRef();

    const flipRef = useRef();
    const {refresh} = useGlobal();
    
    async function onVerifyClick() {

        if(user && user.id) {
            const body = {
                kyc_current: 2
            };

            const isSuccessful = await approveUserKyc(body, user.id);

            if(isSuccessful) {
                refresh('/admin/user', 'users');
                setShowModal(false);
            }
        }
    }

    function onDenyClick() {
        setShowModal(false);
    }

    const userPhoto = docs[3] ? docs[3] : docs[2];
    
    return (
        <>
            {docs &&
                <div className="max-w-full">
                    <div className="w-full mb-10">
                        <h3 className="text-white font-bold mb-5">User details</h3>
                        <UserDetails user={{...user, ...misc, ...docs}} />
                    </div>
                    <div className="gs-12 gap-7 mb-10 mt-10">
                        <div className="xs gs-1-2">
                            <h3 className="text-white font-bold mb-5">User photo</h3>
                            {userPhoto && userPhoto.base64 && <img className="rounded-lg w-full h-auto" alt="user_photo" src={`${userPhoto.base64}`} />}
                            <div className="w-full flex mt-5 justify-end">
                                <a href={userPhoto && userPhoto.base64} className="invisible h-0" ref={downloadRefHidden3} download={`vb_verify_${userPhoto && user.name_first + '_' + user.name_last + '_selfie.' + userPhoto.extension}`}>Download File</a>
                                <Button
                                    color="gray"
                                    buttonType="filled"
                                    size="regular"
                                    rounded={true}
                                    block={false}
                                    iconOnly={true}
                                    ripple="light"
                                    ref={downloadRef}
                                    href="test_file.zip"
                                    onClick={() => downloadRefHidden3 && downloadRefHidden3.current && downloadRefHidden3.current.click()}
                                >
                                    <Icon name="download" size="sm" />
                                </Button>
                                <Tooltips placement="bottom" ref={downloadRef}>
                                    <TooltipsContent>Download Image</TooltipsContent>
                                </Tooltips>
                            </div>
                        </div>
                        <div className="xs gs-2-2">
                            <h3 className="text-white font-bold mb-5">ID document</h3>
                            {docs[0] && docs[1] && docs[0].base64 && docs[1].base64 && <img className="rounded-lg w-full h-auto" alt="user_id_document" src={!flipped ?  `${docs[0].base64}` : `${docs[1].base64}`} /> }
                            <div className="w-full flex mt-5 justify-end">
                                {!flipped ? 
                                    <a href={docs[0] && docs[0].base64} className="invisible h-0" ref={downloadRefHidden0} download={docs[0] && `vb_verify_${user.name_first + '_' + user.name_last + '_id_front.' + docs[0].extension}`}>Download File</a>
                                    :
                                    <a href={docs[1] && docs[1].base64} className="invisible h-0" ref={downloadRefHidden1} download={docs[1] && `vb_verify_${user.name_first + '_' + user.name_last + '_id_back.' + docs[1].extension}`}>Download File</a>
                                }
                                <Button
                                    color="gray"
                                    buttonType="filled"
                                    size="regular"
                                    rounded={true}
                                    block={false}
                                    iconOnly={true}
                                    ripple="light"
                                    ref={downloadRef}
                                    onClick={() => !flipped ? 
                                        (downloadRefHidden0 && downloadRefHidden0.current && downloadRefHidden0.current.click()) 
                                        : 
                                        (downloadRefHidden1 && downloadRefHidden1.current && downloadRefHidden1.current.click()) 
                                    }
                                >
                                    <Icon name="download" size="sm" />
                                </Button>
                                <Button
                                    color="gray"
                                    buttonType="filled"
                                    className="ml-2"
                                    size="regular"
                                    rounded={true}
                                    block={false}
                                    iconOnly={true}
                                    ripple="light"
                                    ref={flipRef}
                                    onClick={() => setFlipped(!flipped)}
                                >
                                    <Icon name="flip_camera_android" size="sm" />
                                </Button>

                                <Tooltips placement="bottom" ref={flipRef}>
                                    <TooltipsContent>View {flipped ? "front" : "back"} of document</TooltipsContent>
                                </Tooltips>
                                <Tooltips placement="bottom" ref={downloadRef}>
                                    <TooltipsContent>Download Image</TooltipsContent>
                                </Tooltips>
                            </div>
                        </div>

                    </div>
                    <div className="h-10"></div>
                    <Button 
                        onClick={onVerifyClick} 
                        className="inline float-right border-primary" 
                        buttonType="fill"
                    >
                        Verify
                    </Button>
                    <Button 
                        onClick={onDenyClick} 
                        color="red" 
                        className="inline mr-2 hover:bg-red-500 hover:text-white float-right border-primary" 
                        buttonType="outline"
                    >
                        Deny verification
                    </Button>
                </div>
            }
        </>
    )
}
