import {useRef, useState, useEffect} from 'react';

import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import Progress from '@material-tailwind/react/Progress';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";

import Modal from 'components/general/Modal';
import Pagination from 'components/general/Pagination';
import FundView from './FundView';
import DeleteFundsConfirmationModal from './DeleteFundConfirmationModal';

import { useGlobalState } from 'state-pool';
import { useGlobal } from 'hooks/useGlobalState';
import {deleteFund} from 'routes/fundRoutes';
import { numberWithSpaces } from 'utils';

import {useHistory} from 'react-router-dom';

const PER_PAGE = 10;

export default function FundsTable() {
    const [funds] = useGlobalState('funds');
    const [fund, setFund] = useGlobalState('fund');
    const [showViewFundModal, setShowViewFundModal] = useState(false);
    const [showDeleteFundConfirmation, setShowDeleteFundConfirmation] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [numPages, setNumPages] = useState(1);
    const [showFrom, setShowFrom] = useState(0);
    const [showTo, setShowTo] = useState(PER_PAGE);

    const tooltipRef = useRef();
    const {refresh} = useGlobal();
    const history = useHistory();

    const onDeleteFundClick = async (id) => {
        const isSuccessful = await deleteFund(id);

        if(isSuccessful) refresh('/admin/funds', 'funds');
    }

    useEffect(() => {
        if(funds && Array.isArray(funds) && funds.length > 0) {
            const numfunds = funds.length;

            const tempNumPages = Math.ceil(numfunds / PER_PAGE);

            setNumPages(tempNumPages);
        }
    }, [funds]);

    useEffect(() => {
        setShowFrom(currentPage * PER_PAGE);
        setShowTo((currentPage * PER_PAGE) + PER_PAGE);
    }, [currentPage]);
    
    return (
        <>
            <Card className="bg-primary">
                <CardHeader color="gray" contentPosition="left">
                    <h2 className="text-white text-2xl inline float-left">Funds</h2>
                </CardHeader>
                <CardBody>
                    <div className="overflow-x-auto">
                        <table className="items-center w-full bg-transparent border-collapse">
                            <thead>
                                <tr>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Name
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Type
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Min buy in
                                    </th>
                                    {/* <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Movement
                                    </th> */}
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Users limit
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Users current
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        Current/limit
                                    </th>
                                    <th className="px-2 text-white text-cream align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-normal text-left">
                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {funds && Array.isArray(funds) && funds.length > 0 && funds.slice(showFrom, showTo).map((f, i) => (
                                    <tr key={i}>
                                        <th className="border-b border-gray-200 text-white align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                            <i ref={tooltipRef} className="fas fa-circle fa-sm text-green-500 mr-2"></i>
                                            <Tooltips placement="bottom" ref={tooltipRef}>
                                                <TooltipsContent>active</TooltipsContent>
                                            </Tooltips>
                                        </th>
                                        <th className="border-b border-gray-200 text-white align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                            {f.name}
                                        </th>
                                        <th className="border-b border-gray-200 text-white align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                            {f.type}
                                        </th>
                                        <th className="border-b border-gray-200 text-white align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                            {`R ${numberWithSpaces(f.min_amount)}`}
                                        </th>
                                       {/*  <th className="border-b border-gray-200 text-white align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                            {f.movement ? f.movement : '0%'}
                                        </th> */}
                                        <th className="border-b border-gray-200 text-white align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                            {f.users_limit}
                                        </th>
                                        <th className="border-b border-gray-200 text-white align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                            {f.users_current ? f.users_current : 0}
                                        </th>
                                        <th className="border-b border-gray-200 text-white align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-left">
                                            <Progress color="red" value={((f.users_current ? f.users_current : 0)/f.users_limit)*100} />
                                        </th>
                                        <th className="border-b border-gray-200 align-middle font-normal text-sm whitespace-nowrap px-2 py-4 text-white text-left">
                                                <div className="flex">
                                                    <Button
                                                        size="sm"
                                                        color="gray"
                                                        onClick={() => {
                                                            setFund({...f});
                                                            setShowViewFundModal(true);
                                                        }}
                                                        iconOnly
                                                        rounded
                                                        ref={tooltipRef}
                                                    >
                                                        <Icon name="visibility" color="white" />
                                                    </Button>
                                                    <Tooltips placement="bottom" ref={tooltipRef}>
                                                        <TooltipsContent>view fund</TooltipsContent>
                                                    </Tooltips>
                                                    <Button
                                                        size="sm"
                                                        color="gray"
                                                        onClick={() => {
                                                            setFund({...f});
                                                            history.push(`/funds/edit/${f.id}`);
                                                        }}
                                                        className="ml-2"
                                                        iconOnly
                                                        rounded
                                                        ref={tooltipRef}
                                                    >
                                                        <Icon name="edit" color="white" />
                                                    </Button>
                                                    <Tooltips placement="bottom" ref={tooltipRef}>
                                                        <TooltipsContent>edit fund</TooltipsContent>
                                                    </Tooltips>
                                                    <Button
                                                        size="sm"
                                                        color="red"
                                                        onClick={() => {
                                                            setFund({...f});
                                                            setShowDeleteFundConfirmation(true);
                                                        }}
                                                        className="ml-2"
                                                        iconOnly
                                                        rounded
                                                        ref={tooltipRef}
                                                    >
                                                        <Icon name="delete" color="white" />
                                                    </Button>
                                                    <Tooltips placement="bottom" ref={tooltipRef}>
                                                        <TooltipsContent>delete fund</TooltipsContent>
                                                    </Tooltips>
                                                </div>
                                        </th>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
            <div className='flex w-100 justify-center'>
                <Pagination 
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    numPages={numPages}
                />
            </div>
            {fund && <Modal 
                showModal={showViewFundModal}
                setShowModal={setShowViewFundModal}
                title={fund && fund.name}
                size="regular"
                onClose={() => {
                    setFund(null);
                    setShowViewFundModal(false);
                }}
            >
                <FundView fund={fund} />
            </Modal>}
            {fund && <DeleteFundsConfirmationModal 
                showModal={showDeleteFundConfirmation}
                setShowModal={setShowDeleteFundConfirmation}
                name={fund.name}
                deleteFund={() => onDeleteFundClick(fund.id)}
            />}
        </>
    );
}
