import H1 from '@material-tailwind/react/Heading1';
import H3 from '@material-tailwind/react/Heading3';
import { Helmet } from 'react-helmet';

export default function NotFound() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>404 - Venturbay</title>
            </Helmet>
            <div className="relative pt-16 pb-32 flex content-center items-center justify-center h-screen">
                <div className="bg-landing-background bg-cover bg-center absolute top-0 w-full h-full" />
                <div className="container max-w-8xl relative mx-auto">
                    <div className="items-center flex flex-wrap">
                        <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                            <H1 color="black">404</H1>
                            <H3 color="black">
                                Page not found
                            </H3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}