export default function AddressDetails({address}) {
    return (
        <div className="w-full text-left text-white">
            {address && (
                <>
                    <div className="w-full text-white">
                        <p className="text-white">{address}</p>
                        {/* <div className="xs gs-1-2">
                            <p className="text-white font-normal">Street</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{address.street}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Suburb</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{address.suburb}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">City/Town</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{address.city}</p>
                        </div>
                        <div className="xs gs-1-2">
                            <p className="text-white font-normal">Postal Code</p>
                        </div>
                        <div className="xs gs-2-2 text-right">
                            <p className="text-white font-bold">{address.postalCode}</p>
                        </div> */}
                    </div>
                </>
            )}
        </div>
    )
}